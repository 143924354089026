import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  package_common_details,
  package_common_path,
  updateRedux,
} from "../../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../../constants/configuration";

export default function StressFree({ flightBooking, bookNowHandler }) {
  const dispatch = useDispatch();

  const packageCommonDetails = useSelector(package_common_details);
  const packageCommonPath = useSelector(package_common_path);

  return (
    <div className="stress__free">
      <div className="container">
        <h4>{packageCommonDetails?.package_footer_title}</h4>
        <div className="row align-items-center">
          <div className="col-md-8">
            <div className="features">
              {packageCommonDetails?.package_footer_list?.map((ele, i) => {
                return (
                  <div className="gridItem">
                    <img
                      src={`${IMAGE_BASE_URL}${packageCommonPath}/${ele?.icon}`}
                      alt={ele.icon_alt}
                    />
                    <h5>{ele?.title}</h5>
                    <p>{ele?.description}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-4">
            <div className="search_flight">
              <h4>{packageCommonDetails?.package_short_title}</h4>
              <p>{packageCommonDetails?.package_short_description}</p>
              <div className="search__flight">
                <div className="select__box">
                  <div className="txt" for="inlineCheckbox1">
                    <h4>Flight Booking Required?</h4>
                  </div>
                  <div className="flex">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        checked={flightBooking == true}
                        onChange={(e) => {
                          dispatch(
                            updateRedux({
                              key: "flight_booking",
                              value: e?.target.checked,
                            })
                          );
                        }}
                      />
                    </div>
                    <p>Select if you want to include flight book</p>
                  </div>

                </div>
                <div className="explore">
                  <button
                    className="btn btn-white"
                    onClick={() => bookNowHandler()}
                  >
                    {flightBooking ? "Search Flight" : "Book Now"}{" "}
                    <img src="/assets/img/icons/button-arrow.svg" alt="" />
                  </button>
                </div>
              </div>
              {/* <button className="btn btn-white" style={{ maxWidth: "200px" }}>
                Book Now <img src="/assets/img/icons/btn-arrow.svg" alt="" />
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
