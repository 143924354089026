import { useDispatch, useSelector } from "react-redux";
import "./payment.css";
import { useEffect, useState } from "react";
import {
  getBookingPayment,
  uploadFile,
} from "../../../redux/adminActionCreator";
import {
  accounts_details,
  booking_payment_details,
  booking_payment_loading,
  booking_status,
} from "../../../redux/adminReducer";
import { useNavigate } from "react-router-dom";

const PaymentQuote = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bookingId = localStorage.getItem("booking_id");

  const bookingDetails = JSON.parse(localStorage.getItem("booking_details"));
  const bookingStatus = useSelector(booking_status) ?? {};

  const details = useSelector(booking_payment_details);
  const loading = useSelector(booking_payment_loading);
  const accountDetails = useSelector(accounts_details);

  const [err, setErr] = useState({});
  const [invalidUpload, setInvalidUpload] = useState(false);
  const [trackId, setTrackId] = useState("");
  const [receipt, setReceipt] = useState([]);

  useEffect(() => {
    if (bookingId) {
      dispatch(getBookingPayment(bookingId));
    }
  }, [bookingId]);

  const fileSelectedHandler = (e, isInvalid, message, isClosing) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErr({
        ...err,
        receipt: message,
      });
    } else {
      setErr({
        ...err,
        receipt: "",
      });
      setInvalidUpload(false);
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        e?.filter((el) => el?.metaFile != undefined)?.forEach((item, i) =>
          formData.append(`file`, item?.metaFile)
        );
        dispatch(
          uploadFile(formData, (res) => {
            let oldFiles = [];
            oldFiles = e?.filter((el) => el?.metaFile == undefined);
            let arr = e
              ?.filter((el) => el?.metaFile != undefined)
              ?.map((ele, i) => {
                return {
                  // ...ele,
                  type: ele?.type,
                  url: ele?.url,
                  name: ele?.name,
                  response: res?.data[i],
                  id: res?.data[i] + i + 1,
                  alt: "",
                };
              });
            let tempValue = [...oldFiles, ...arr];
            setReceipt(tempValue);
          })
        );
      } else {
        setReceipt(e);
      }
    }
  };

  return (
    <section id={"payment"}>
      <div className="container px-4">
        <div className="row">
          <div className="col-12 py-3">
            <div className="payment_card">
              <div className="payment_header">
                <h4>FINAL QUOTE</h4>
              </div>

              <div className="row py-2 px-5">
                <div className="col-3">
                  <p className="payment_package">Package Name</p>
                </div>
                <div className="col-9">
                  <p className="payment_package_name">
                    {bookingDetails?.bookingitinerary?.package_name}
                  </p>
                </div>
              </div>
              <div className="table-responsive">
              <table className={"payment_table"}>
                <thead>
                  <tr>
                    <th scope="col">Final Quote</th>
                    <th scope="col">Unit Cost (USD)</th>
                    <th scope="col">Unit</th>
                    <th scope="col">Total (USD)</th>
                  </tr>
                </thead>
                <tbody className={"table_row"}>
                  <tr>
                    <th className="title" scope={"row"}>
                      {"Package Cost"}
                    </th>
                    <th />
                    <th />
                    <th>
                      {/* {details?.bookingitinerary?.total_estimate?.toFixed(2)} */}
                    </th>
                  </tr>
                  {details?.bookingitinerary?.quotation?.map((ele, i) => (
                    <tr key={i}>
                      <td style={{ paddingLeft: 32 }}>{ele.label}</td>
                      <td>{ele.unit_cost?.toFixed(2)}</td>
                      <td>{ele.units}</td>
                      <td>{ele.total_cost?.toFixed(2)}</td>
                    </tr>
                  ))}
                  {details?.bookingitinerary?.airfare_quotation?.length ? (
                    <>
                      <tr>
                        <th className="title" scope={"row"}>
                          {"Airfare Cost"}
                        </th>
                        <th />
                        <th />
                        <th></th>
                      </tr>
                      {details?.bookingitinerary?.airfare_quotation?.map(
                        (ele, i) => (
                          <tr key={i}>
                            <td style={{ paddingLeft: 32 }}>{ele.label}</td>
                            <td>{ele.unit_cost?.toFixed(2)}</td>
                            <td>{ele.units}</td>
                            <td>{ele.total_cost?.toFixed(2)}</td>
                          </tr>
                        )
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <tr>
                    <th className="title" scope={"row"}>
                      {"Gross Total"}
                    </th>
                    <th />
                    <th />
                    <th>{details?.bookingitinerary.gross_total?.toFixed(2)}</th>
                  </tr>
                  <tr>
                    <th scope={"row"}>{"Payment Completed"}</th>
                    <th />
                    <th />
                    <th>{details?.bookingitinerary.amount_paid ?? 0}</th>
                  </tr>
                  <tr>
                    <th scope={"row"}>{"Additional Cost"}</th>
                    <th />
                    <th />
                    <th>{details?.bookingitinerary.additional_cost ?? 0}</th>
                  </tr>
                  <tr>
                    <th scope={"row"}>{"Discounts"}</th>
                    <th />
                    <th />
                    <th>{details?.bookingitinerary.total_discount ?? 0}</th>
                  </tr>
                  <tr>
                    <th scope={"row"}>{"Deductions"}</th>
                    <th />
                    <th />
                    <th>{details?.bookingitinerary.deduction ?? 0}</th>
                  </tr>
                  <tr>
                    <th scope={"row"}>{"Total To Be Paid"}</th>
                    <th />
                    <th />
                    <th>
                      {/* {Number(details?.bookingitinerary?.total_estimate) -
                        Number(details?.bookingitinerary?.amount_paid) >
                      0
                        ? (
                            Number(details?.bookingitinerary?.total_estimate) -
                            Number(details?.bookingitinerary?.amount_paid)
                          )?.toFixed(2)
                        : 0} */}
                        {details?.bookingitinerary?.amount_to_be_paid}
                    </th>
                  </tr>
                </tbody>
              </table>
              </div>

              <div className={"px-5 pt-3 border-bottom"}>
                <h5 className={"text-lg fw-bold font-archivo-expanded mb-0"}>
                  Payment Method
                </h5>
                <div className="traveller_submit d-flex justify-content-between align-items-center mb-4">
                  <p className="text-lg fw-normal font-archivo-expanded mb-0">
                    {details?.bookingpayment?.payment_mode === "onetime"
                      ? "One Time"
                      : "Milestone"}{" "}
                    |{" "}
                    {details?.customerengagements?.payment_method === "direct"
                      ? "Direct Bank"
                      : "Online Payment"}
                  </p>

                  {bookingStatus?.is_payment ? (
                    <button
                      type="submit"
                      style={{ textWrap: "nowrap", width: "auto" }}
                      onClick={() => navigate("/payment-section")}
                    >
                      View Payment Section
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              {/* {details?.bookingpayment?.payment_mode === "onetime" &&
              details?.customerengagements?.payment_method === "direct" ? (
                <div className={"px-5 pt-2 pb-4 border-bottom"}>
                  <div className={"row"}>
                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <FileUpload
                          style={{
                            marginRight: 5,
                            marginTop: 15,
                          }}
                          className="css-atc-esc2 mt-0 mb-0 form-label"
                          label="Upload receipt"
                          noLabel
                          buttonLabel="Browse"
                          accept={["jpg", "jpeg", "png", "svg"]}
                          onChange={(e, isInvalid, message, isClosing) => {
                            fileSelectedHandler(
                              e,
                              isInvalid,
                              message,
                              isClosing
                            );
                          }}
                          field={"receipt"}
                          error={err[`${"receipt"}`]}
                          id="receipt"
                          maxFileSizeMB={100}
                          files={receipt}
                          inputSocket={() => {}}
                          showPreview
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 traveller_submit">
                      <button
                        className="d-flex justify-content-center align-items-center gap-4"
                        type="submit"
                        style={{ marginTop: "35px" }}
                      >
                        Submit{" "}
                        <span>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18.677"
                            height="12.681"
                            viewBox="0 0 18.677 12.681"
                          >
                            <g
                              id="Group_39998"
                              data-name="Group 39998"
                              transform="translate(0 0.72)"
                            >
                              <path
                                id="Path_104"
                                data-name="Path 104"
                                d="M-445.866,771.389l5.627,5.419-5.835,5.835"
                                transform="translate(457.489 -771.389)"
                                fill="none"
                                stroke="#fff"
                                stroke-width="2"
                              />
                              <line
                                id="Line_12"
                                data-name="Line 12"
                                x1="17.249"
                                transform="translate(0 5.388)"
                                fill="none"
                                stroke="#fff"
                                stroke-width="2"
                              />
                            </g>
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )} */}

              {details?.customerengagements?.payment_method === "direct" ? (
                <>
                  <div className="px-5 py-3 border-bottom">
                    <h6 className="font-archivo-expanded">
                      You need to visit your branch bank and transfer the amount
                      to the given account and upload the receipt in payment
                      section
                    </h6>
                  </div>
                  <div className="px-5 py-3">
                    <div className="row font-archivo-expanded">
                      <div className="col-3">
                        <div className="mb-3">
                          <h3 className="text-sm">Name</h3>
                          <p className="text-sm text-muted">
                            {accountDetails?.account_name}
                          </p>
                        </div>
                        <div className="mb-3">
                          <h3 className="text-sm">Account Number</h3>
                          <p className="text-sm text-muted">
                            {accountDetails?.account_number}
                          </p>
                        </div>
                        <div className="mb-3">
                          <h3 className="text-sm">Routing Code</h3>
                          <p className="text-sm text-muted">
                            {accountDetails?.routing_code}
                          </p>
                        </div>
                      </div>
                      <div className="col-9 position-relative">
                        <hr className="FooterDivider" />
                        <div className="px-5">
                          <h6 className="text-sm">TERMS & CONDITIONS</h6>
                          <p
                            className="text-muted FooterTCDesc"
                            dangerouslySetInnerHTML={{
                              __html: accountDetails?.terms_conditions,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentQuote;
