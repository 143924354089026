import React from "react";
import { IMAGE_BASE_URL } from "../../../../constants/configuration";
import { useSelector } from "react-redux";
import { package_common_details, package_common_path } from "../../../../redux/commonReducer";

export default function PackageExpect() {
  const packageCommonDetails = useSelector(package_common_details);
  const packageCommonPath = useSelector(package_common_path);
  return (
    <div className="stress__free">
      <div className="container">
        <h4>{packageCommonDetails?.flight_footer_title}</h4>
        <div className="row align-items-center">
          <div className="col-md-8">
            <div className="features">
              {packageCommonDetails?.flight_footer_list?.map((ele, i) => {
                return (
                  <div className="gridItem centerAlign">
                    <img
                      src={`${IMAGE_BASE_URL}${packageCommonPath}/${ele?.icon}`}
                      alt={ele.icon_alt}
                    />
                    <h5>{ele?.title}</h5>
                    <p>{ele?.description}</p>
                  </div>
                );
              })}
              {packageCommonDetails?.package_included?.length ? (
                <>
                  <div className="gridItem lists">
                    <ul>
                      {packageCommonDetails?.package_included?.map((ele, i) => {
                        return (
                          <li>
                            <img
                              src={`${IMAGE_BASE_URL}${packageCommonPath}/${ele?.icon}`}
                              alt={ele?.icon_alt}
                              height={20}
                            />{" "}
                            {ele?.title}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="search_flight">
              <h4>{packageCommonDetails?.flight_short_title}</h4>
              <p>{packageCommonDetails?.flight_short_description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
