import React, { useEffect, useState } from "react";
import "./traveller.css";
import { useDispatch, useSelector } from "react-redux";
import {
  booking_details,
  booking_details_loading,
  country_list,
} from "../../../redux/commonReducer";
import {
  getBookingDetails,
  getCountry,
} from "../../../redux/commonActionCreator";
import moment from "moment";
import { IMAGE_BASE_URL } from "../../../constants/configuration";
import DatePicker from "react-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import {
  updateTravellerInfo,
  uploadFile,
} from "../../../redux/adminActionCreator";
import FileUpload from "../../../commonComponents/FileUpload/FileUpload";
import LoadingSpinner from "../../../commonComponents/LoadingSpinner/LoadingSpinner";
import {
  fileUploadLoader,
  update_traveler_loader,
} from "../../../redux/adminReducer";
import { Spinner } from "react-bootstrap";

const TravellerDetails = () => {
  const dispatch = useDispatch();
  const bookingId = localStorage.getItem("booking_id");

  const twelveYearsAgo = new Date();
  twelveYearsAgo.setFullYear(twelveYearsAgo.getFullYear() - 12);

  const details = useSelector(booking_details);
  const countryData = useSelector(country_list);
  const loading = useSelector(booking_details_loading);
  const uploadLoader = useSelector(fileUploadLoader);
  const updateLoader = useSelector(update_traveler_loader);

  const [data, setData] = useState([]);
  const [err, setErr] = useState({});
  const [invalidUpload, setInvalidUpload] = useState(false);
  const [trackId, setTrackId] = useState("");

  const travelerObj = {
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    phone: "",
    country_code: "",
    nationality: "",
    dob: "",
    passport_no: "",
    passport_expiry: "",
    passport: [],
    attachments: [],
  };

  useEffect(() => {
    dispatch(getCountry());
  }, []);

  useEffect(() => {
    if (bookingId) {
      dispatch(getBookingDetails(bookingId));
    }
  }, [bookingId]);

  useEffect(() => {
    if (details && bookingId) {
      let result = [];
      if (Number(details?.no_of_travellers)) {
        let temp = [];
        let adult = 0;
        let child = 0;
        let total_adult = Number(details?.no_of_adults);
        let total_child =
          Number(details?.no_of_travellers) - Number(details?.no_of_adults);
        for (let i = 0; i < Number(details?.no_of_travellers); i++) {
          if (details?.traveller_info?.[i]) {
            let obj = details?.traveller_info[i];
            if (obj.is_adult == "1") adult++;
            if (obj.is_child == "1") child++;
            temp.push({
              ...travelerObj,
              ...obj,
              nationality:
                countryData?.filter(
                  (ele) => ele?.name === obj?.nationality
                )?.[0] ?? "",
              country_code:
                countryData?.filter(
                  (ele) => ele?.dial_code === obj?.country_code
                )?.[0] ?? "",
              child: child,
              adult: adult,
              dob: obj?.dob ? moment(obj?.dob, "DD-MM-YYYY").toDate() : "",
              passport_expiry: obj?.passport_expiry
                ? moment(obj?.passport_expiry, "DD-MM-YYYY").toDate()
                : "",
              passport: obj?.passport
                ? [
                    {
                      type: obj?.passport?.split(".").pop(),
                      url: `${IMAGE_BASE_URL}${"booking"}/${obj?.passport}`,
                      name: `${obj?.passport}`,
                      response: `${obj?.passport}`,
                    },
                  ]
                : "",
              // attachments: [],
              attachments: obj?.attachments?.map((ele, i) => {
                let temp = {
                  type: ele?.split(".").pop(),
                  url: `${IMAGE_BASE_URL}booking/${ele}`,
                  name: `${ele}`,
                  response: `${ele}`,
                };
                return temp;
              }),
            });
          } else {
            let is_adult;
            if (total_adult - adult > 0) {
              is_adult = true;

              adult++;
            } else if (total_adult - adult == 0 && total_child - child > 0) {
              is_adult = false;
              child++;
            }
            temp.push({
              ...travelerObj,
              is_main: "0",
              is_adult: is_adult ? "1" : "0",
              is_child: !is_adult ? "1" : "0",
              child: child,
              adult: adult,
            });
          }
        }
        let adultTravelers = temp?.filter((ele) => ele?.is_adult == "1");
        let childTravelers = temp?.filter((ele) => ele?.is_child == "1");
        result = [...adultTravelers, ...childTravelers];
        setData([...result]);
      }
    }
  }, [details, countryData]);

  const dataChangeHandler = (value, key, index) => {
    let dataList = [...data];
    dataList[index][key] = value;
    setData(dataList);
    setErr({
      ...err,
      [`${key}_${index}`]: "",
    });
  };

  const fileSelectedHandler = (
    e,
    isInvalid,
    message,
    isClosing,
    field,
    setFunction,
    index,
    type
  ) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErr({
        ...err,
        [`${field}_${index}`]: message,
      });
    } else {
      setErr({
        ...err,
        [`${field}_${index}`]: message,
      });
      setInvalidUpload(false);
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        e?.filter((el) => el?.metaFile != undefined)?.forEach((item, i) =>
          formData.append(`file`, item?.metaFile)
        );
        formData.append(`path`, "booking");
        dispatch(
          uploadFile(formData, (res) => {
            let oldFiles = [];
            if (type !== "single") {
              oldFiles = e?.filter((el) => el?.metaFile == undefined);
            }
            let arr = e
              ?.filter((el) => el?.metaFile != undefined)
              ?.map((ele, i) => {
                return {
                  // ...ele,
                  type: ele?.type,
                  url: ele?.url,
                  name: ele?.name,
                  response: res?.data[i],
                  id: res?.data[i] + i + 1,
                  alt: "",
                };
              });
            let tempValue = [...oldFiles, ...arr];
            setFunction(tempValue, field, index);
          })
        );
      } else {
        setErr({
          ...err,
          [`${field}_${index}`]: message,
        });
        setFunction(e, field, index);
      }
    }
  };

  const onCancelHandler = () => {
    setErr({});
    dispatch(getBookingDetails(bookingId));
  };

  const validate = () => {
    let temp = {};
    if (data?.length) {
      data?.forEach((ele, i) => {
        if (!ele?.first_name?.trim()?.length) {
          temp[`${"first_name"}_${i}`] = "Please enter first name";
        }
        if (!ele?.last_name?.trim()?.length) {
          temp[`${"last_name"}_${i}`] = "Please enter last name";
        }
        if (ele?.dob == "") {
          temp[`${"dob"}_${i}`] = "Please select dob";
        }
        if (ele?.nationality == "") {
          temp[`${"nationality"}_${i}`] = "Please select nationality";
        }
        if (!ele?.email?.trim()?.length) {
          temp[`${"email"}_${i}`] = "Please enter email";
        } else {
          let regex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
          if (!regex.test(ele?.email.trim())) {
            temp[`${"email"}_${i}`] = "Please enter valid email";
          }
        }
        if (!ele?.phone?.trim()?.length) {
          temp[`${"phone"}_${i}`] = "Please enter phone number";
        }
        if (ele?.country_code ==="") {
          temp[`${"country_code"}_${i}`] = "Please select country code";
        }
        if (!ele?.passport_expiry ) {
          temp[`${"passport_expiry"}_${i}`] =
            "Please select passport expiry date";
        }
        if (ele?.passport_no == "") {
          temp[`${"passport_no"}_${i}`] = "Please enter passport number";
        }
        if (!ele?.passport?.length) {
          temp[`${"passport"}_${i}`] = "Please upload passport";
        }
      });
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };
       console.log(err,"eroror")
  const onSubmitHandler = async (type) => {
    if (type == "save") {
      if (!invalidUpload) {
        finalSubmitHandler(type);
      }
    } else {
      let isValidated = validate();
      if (!invalidUpload && isValidated) {
        finalSubmitHandler(type);
      }
    }
  };

  const finalSubmitHandler = (type) => {
    let raw = {
      booking_id: bookingId,
      track_id: trackId,
      type: type,
      traveller_info: data?.map((item, i) => {
        return {
          [`first_name`]: item?.first_name?.trim(),
          [`middle_name`]: item?.middle_name?.trim(),
          [`last_name`]: item?.last_name?.trim(),
          [`email`]: item?.email,
          [`phone`]: item?.phone,
          [`country_code`]: item.country_code?.dial_code,
          [`nationality`]: item.nationality?.name,
          [`dob`]: item?.dob ? moment(item?.dob).format("DD-MM-YYYY") : "",
          [`passport_no`]: item?.passport_no,
          [`passport_expiry`]: item?.passport_expiry
            ? moment(item?.passport_expiry).format("DD-MM-YYYY")
            : "",
          [`passport`]: item?.passport?.[0]?.response ?? "",
          [`is_main`]: item?.is_main,
          [`is_adult`]: item?.is_adult,
          [`is_child`]: item?.is_child,
          [`attachments`]: item?.attachments?.map((ele, i) => ele?.response),
          [`can_login`]: item?.can_login,
        };
      }),
    };
    console.log(raw, "raw");
    dispatch(
      updateTravellerInfo(raw, (res) => {
        if (res.status) {
          onCancelHandler();
        }
      })
    );
  };

  return (
    console.log(data),
    (
      <section id="travel_details" className="main-section">
        <div className="container">
          <div className="row">
            <div className="col-12 py-3 ">
              <div class="card main-header">
                <div class="card-body">
                  <h3>
                    Let's complete your travel companions information to
                    finalize your booking..
                  </h3>
                  <p>
                    Please provide the following details for each traveler,
                    including yourself. Ensure all passports are valid for at
                    least six months beyond your travel dates.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <LoadingSpinner height={"200px"} />
        ) : (
          <>
            {data?.map((ele, index) => {
              return (
                <div className="pt-2">
                  <div className="container">
                    <div className="card">
                      <div className="card-body p-4">
                        <h5 className="card_heading">
                          {ele?.is_main == "1"
                            ? "Main Traveller"
                            : ele?.is_adult == "1"
                            ? `Adult ${ele?.adult}`
                            : `Child ${ele?.child}`}
                        </h5>

                        <div className="row">
                          <div className="col-6 col-md-3 ">
                            <div className="mb-3">
                              {index == 0 ? (
                                <>
                                  <p className="passenger_label">First Name</p>
                                  <p className="passenger_Details">
                                    {ele?.first_name}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <label className="form-label">
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter first name"
                                    value={ele?.first_name}
                                    onChange={(e) => {
                                      dataChangeHandler(
                                        e?.target?.value,
                                        "first_name",
                                        index
                                      );
                                    }}
                                  />
                                  <small className="error">
                                    {err[`${"first_name"}_${index}`]}
                                  </small>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-6 col-md-3 ">
                            <div className="mb-3">
                              {index == 0 ? (
                                <>
                                  <p className="passenger_label">Middle Name</p>
                                  <p className="passenger_Details">
                                    {ele?.middle_name}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <label className="form-label">
                                    Middle Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter middle name"
                                    value={ele?.middle_name}
                                    onChange={(e) => {
                                      dataChangeHandler(
                                        e?.target?.value,
                                        "middle_name",
                                        index
                                      );
                                    }}
                                  />
                                  <small className="error">
                                    {err[`${"middle_name"}_${index}`]}
                                  </small>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-6 col-md-3 ">
                            <div className="mb-3">
                              {index == 0 ? (
                                <>
                                  {" "}
                                  <p className="passenger_label">Last Name</p>
                                  <p className="passenger_Details">
                                    {ele?.last_name}
                                  </p>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <label className="form-label">
                                    Last Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter last name"
                                    value={ele?.last_name}
                                    onChange={(e) => {
                                      dataChangeHandler(
                                        e?.target?.value,
                                        "last_name",
                                        index
                                      );
                                    }}
                                  />
                                  <small className="error">
                                    {err[`${"last_name"}_${index}`]}
                                  </small>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-6 col-md-3 ">
                            <div className="mb-3">
                              {index == 0 ? (
                                <>
                                  <p className="passenger_label">
                                    Date of Birth
                                  </p>
                                  <p className="passenger_Details">
                                    {moment(ele?.dob)?.format("DD/MM/YYYY")}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <label className="form-label">
                                    Date of birth
                                  </label>
                                  <div className="date-picker-wrapper">
                                    <DatePicker
                                      className="form-control"
                                      placeholderText="dd/mm/yyyy"
                                      dateFormat="dd/MM/yyyy"
                                      selected={ele?.dob}
                                      onChange={(date) => {
                                        dataChangeHandler(date, "dob", index);
                                      }}
                                      // maxDate={new Date()}
                                      showMonthDropdown
                                      scrollableYearDropdown
                                      yearDropdownItemNumber={150}
                                      showYearDropdown
                                      maxDate={
                                        ele?.is_adult == "1"
                                          ? twelveYearsAgo
                                          : new Date()
                                      }
                                      minDate={
                                        ele?.is_adult == "0"
                                          ? twelveYearsAgo
                                          : ""
                                      }
                                    />
                                    <FaCalendarAlt className="calendar-icon" />
                                  </div>
                                  <small className="error">
                                    {" "}
                                    {err[`${"dob"}_${index}`]}
                                  </small>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6 col-md-3 ">
                            <div className="mb-3">
                              {index == 0 ? (
                                <>
                                  {" "}
                                  <p className="passenger_label">Nationality</p>
                                  <p className="passenger_Details">
                                    {ele?.nationality?.name}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <label className="form-label">
                                    Nationality
                                  </label>
                                  <Select
                                    placeholder="Select Your Nationality"
                                    name="nationality"
                                    options={countryData}
                                    value={ele?.nationality}
                                    getOptionLabel={(o) => o?.name}
                                    getOptionValue={(o) => o?._id}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(e) => {
                                      dataChangeHandler(
                                        e,
                                        "nationality",
                                        index
                                      );
                                    }}
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                  <small className="error">
                                    {err[`${"nationality"}_${index}`]}
                                  </small>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-6 col-md-3 ">
                            <div className="mb-3">
                              {index == 0 ? (
                                <>
                                  {" "}
                                  <p className="passenger_label">
                                    Email Address
                                  </p>
                                  <p className="passenger_Details">
                                    {ele?.email}
                                  </p>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <label className="form-label">
                                    Email Address
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter email address"
                                    value={ele?.email}
                                    onChange={(e) => {
                                      dataChangeHandler(
                                        e?.target?.value,
                                        "email",
                                        index
                                      );
                                    }}
                                  />
                                  <small className="error">
                                    {" "}
                                    {err[`${"email"}_${index}`]}
                                  </small>
                                </>
                              )}
                            </div>
                          </div>
                          {index == 0 ? (
                            <>
                              {" "}
                              <div className="col-6 col-md-3">
                                <div className="mb-3">
                                  <p className="passenger_label">
                                    Phone Number
                                  </p>
                                  <p className="passenger_Details">
                                    {`${ele?.country_code?.dial_code} ${ele?.phone}`}
                                  </p>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-6 col-md-3 ">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Country Code
                                  </label>
                                  <Select
                                    placeholder="Select Your Country Code"
                                    name="country_code"
                                    options={countryData}
                                    value={ele?.country_code}
                                    getOptionLabel={(o) => o?.dial_code}
                                    getOptionValue={(o) => o?._id}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(e) => {
                                      dataChangeHandler(
                                        e,
                                        "country_code",
                                        index
                                      );
                                    }}
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                  />
                                  <small className="error">
                                    {err[`${"country_code"}_${index}`]}
                                  </small>
                                </div>
                              </div>
                              <div className="col-6 col-md-3">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Phone Number
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter phone number"
                                    value={ele?.phone}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      if (/^\d{0,13}$/.test(inputValue)) {
                                        dataChangeHandler(
                                          inputValue,
                                          "phone",
                                          index
                                        );
                                      }
                                    }}
                                  />
                                  <small className="error">
                                    {" "}
                                    {err[`${"phone"}_${index}`]}
                                  </small>
                                </div>
                              </div>
                            </>
                          )}
                          <div className="passport_details">
                            <p>Passport Details</p>
                          </div>
                          <div className="col-12 col-md-3">
                            <div className="mb-3">
                              <label className="form-label">
                                Passport Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter passport number"
                                value={ele?.passport_no}
                                onChange={(e) => {
                                  dataChangeHandler(
                                    e?.target?.value,
                                    "passport_no",
                                    index
                                  );
                                }}
                              />
                              <small className="error">
                                {" "}
                                {err[`${"passport_no"}_${index}`]}
                              </small>
                            </div>
                          </div>

                          <div className="col-12 col-md-2">
                            <div className="mb-3">
                              <label className="form-label">
                                Passport Expiry
                              </label>
                              <div className="date-picker-wrapper">
                                <DatePicker
                                  className="form-control"
                                  placeholderText="dd/mm/yyyy"
                                  dateFormat="dd/MM/yyyy"
                                  selected={ele?.passport_expiry}
                                  scrollableYearDropdown
                                  onChange={(date) => {
                                    dataChangeHandler(
                                      date,
                                      "passport_expiry",
                                      index
                                    );
                                  }}
                                  showMonthDropdown
                                  showYearDropdown
                                  minDate={new Date()}
                                />
                                <FaCalendarAlt className="calendar-icon" />
                              </div>
                              <small className="error">
                                {err[`${"passport_expiry"}_${index}`]}
                              </small>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div className="mb-3">
                                <FileUpload
                                  style={{
                                    marginRight: 5,
                                    marginTop: 15,
                                  }}
                                  className="css-atc-esc2 mt-0 mb-0 form-label"
                                  label="Upload Passport"
                                  noLabel
                                  buttonLabel="Browse"
                                  accept={[
                                    "jpg",
                                    "jpeg",
                                    "png",
                                    "pdf",
                                    "application/pdf",
                                  ]}
                                  onChange={(
                                    e,
                                    isInvalid,
                                    message,
                                    isClosing
                                  ) => {
                                    fileSelectedHandler(
                                      e,
                                      isInvalid,
                                      message,
                                      isClosing,
                                      "passport",
                                      dataChangeHandler,
                                      index,
                                      "single"
                                    );
                                  }}
                                  field={"passport"}
                                  error={err[`${"passport"}_${index}`]}
                                  id="passport"
                                  maxFileSizeMB={100}
                                  files={ele?.passport}
                                  inputSocket={() => {}}
                                  showPreview
                                />
                              </div>
                            </div>

                            <div className="col-12 col-md-6 ">
                              <div className="mb-3">
                                <FileUpload
                                  style={{
                                    marginRight: 5,
                                    marginTop: 15,
                                  }}
                                  className="css-atc-esc2 mt-0 mb-0 form-label"
                                  label="Additional Documents"
                                  noLabel
                                  buttonLabel="Browse"
                                  accept={[
                                    "jpg",
                                    "jpeg",
                                    "png",
                                    "pdf",
                                    "application/pdf",
                                  ]}
                                  onChange={(
                                    e,
                                    isInvalid,
                                    message,
                                    isClosing
                                  ) => {
                                    fileSelectedHandler(
                                      e,
                                      isInvalid,
                                      message,
                                      isClosing,
                                      "attachments",
                                      dataChangeHandler,
                                      index
                                    );
                                  }}
                                  field={"attachments"}
                                  error={err[`${"attachments"}_${index}`]}
                                  id="attachments"
                                  maxFileSizeMB={100}
                                  multiple={true}
                                  files={ele?.attachments}
                                  inputSocket={() => {}}
                                  showPreview
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="pt-3">
              <div className="container">
                <div className="card">
                  <div className="card-body p-4">
                    <div className="col-12">
                      <div className="traveller_submit">
                        <button
                          onClick={() => onCancelHandler()}
                          disabled={uploadLoader || updateLoader}
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => onSubmitHandler("save")}
                          disabled={uploadLoader || updateLoader}
                        >
                          Save {updateLoader && <Spinner animation="border" />}
                        </button>
                        <button
                          onClick={() => onSubmitHandler("submit")}
                          disabled={uploadLoader || updateLoader}
                        >
                          Submit{" "}
                          {updateLoader && <Spinner animation="border" />}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
    )
  );
};

export default TravellerDetails;
