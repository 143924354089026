import React, { useEffect } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getAttractionDetails } from "../../redux/commonActionCreator";
import { attraction_details, attraction_path } from "../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import { activity } from "../../components/web/config";

export default function AttractionDetails() {
  const dispatch = useDispatch();
  const { id,packageId } = useParams();
  const navigate = useNavigate();

  const details = useSelector(attraction_details);
  const path = useSelector(attraction_path);

  useEffect(() => {
    if (id) {
      if(packageId){
        const row = {
          id,
          packageId
        }
        dispatch(getAttractionDetails(row));
      }else{
        dispatch(getAttractionDetails(id));
      }
      
    }
  }, [id]);

  const nextLoader = () =>{
    
  }

  return (
    <div className="section__activities">
      <img
        className="main"
        src={`${IMAGE_BASE_URL}${path}/${details?.image}`}
        alt={details?.image_alt}
      />
      <div className="container">
        <div
          className={`tag bg-secondary ${
            details?.attractions?.length ? "pointer" : ""
          }`}
          onClick={() =>
            details?.attractions?.length
              ? navigate(
                  `/attraction-details/${details?.attractions?.[0].handle_key}`
                )
              : {}
          }
        >
          Attractions
        </div>
        <h1 data-aos="fade-up" data-aos-duration="1000">
          {details?.title}
        </h1>
        <h2 data-aos="fade-up" data-aos-duration="2000">
          Details
        </h2>
        <p
          data-aos="fade-up"
          data-aos-duration="2500"
          dangerouslySetInnerHTML={{ __html: details?.long_description }}
        ></p>
      </div>
      {details?.attractions?.length ? (
        <div className="more_activities">
          <div className="container">
            <h3>MORE ATTRACTIONS</h3>
            <Slider {...activity} arrows={details?.attractions?.length > 6} >
              {details?.attractions?.map((ele, i) => {
                return (
                  <div
                    className="gridItem"
                    onClick={() =>
                      navigate(`/attraction-details/${ele?.handle_key}`)
                    }
                  >
                    <img
                      src={`${IMAGE_BASE_URL}${path}/${ele?.image}`}
                      alt={ele.image_alt}
                    />
                    <h4>{ele?.title}</h4>
                    <h6 className="attraction">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: ele?.short_description,
                        }}
                      ></p>
                    </h6>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
