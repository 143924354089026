import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <div className="grid-container admin__styles">
      <Header />
      <Sidebar />
      <div className='content-page'>
        <Outlet />
      </div>

    </div>
  );
};

export default Layout;
