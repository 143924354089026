import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { booking_status } from "../../redux/adminReducer";
import { getBookingStatus } from "../../redux/adminActionCreator";
import { Button, Modal } from "react-bootstrap";

function Sidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const bookingStatus = useSelector(booking_status) ?? {};
  const bookingId = localStorage.getItem("booking_id");

  const [logoutModalShow, setLogoutShow] = useState(false);

  useEffect(() => {
    if (bookingId) {
      dispatch(getBookingStatus(bookingId));
    }
  }, [bookingId]);

  const sidebarItems = [
    {
      name: "Overview",
      icon: "/assets/img/sidebar/overview.svg",
      path: "/admin",
      key: null,
    },
    {
      name: "Traveller Details",
      icon: "/assets/img/sidebar/traveller.svg",
      path: "/traveller-details",
      // key: "is_passenger_info",
    },
    {
      name: "Engagement Details",
      icon: "/assets/img/sidebar/engagement.svg",
      path: "/engagement-details",
      key: "is_customer_engagement",
    },
    {
      name: "Itinerary",
      icon: "/assets/img/sidebar/itinerary.svg",
      path: "/itinerary",
      key: "is_itinerary",
    },
    {
      name: "Payments",
      icon: "/assets/img/sidebar/payments.svg",
      path: "/payments",
      key: "is_quotation",
      // additionalCondition: () => bookingStatus.is_payment,
    },
    {
      name: "Documents",
      icon: "/assets/img/sidebar/documents.svg",
      path: "/documents",
      key: "is_itinerary",
    },
  ];

  const handleShow = () => {
    setLogoutShow(true);
  };

  const handleHide = () => {
    setLogoutShow(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("csrf");
    localStorage.removeItem("booking_id");
    localStorage.removeItem("booking_details");
    navigate("/login");
  };



  return (
    <aside id="sidebar">
      <div className="sidebar-title">
        <div className="sidebar-brand">
          <Link to="/">
            <img src="/assets/img/admin/logo.svg" alt="" />
          </Link>
        </div>
        <span className="icon close_icon">X</span>
      </div>
      <ul className="sidebar-list">
        {sidebarItems.map((item, index) => {
          const isDisabled =
            item.key && !bookingStatus[item.key]
              ? true
              : item.additionalCondition
              ? !item.additionalCondition()
              : false;

          return (
            <li key={index} className="sidebar-list-item">
              {isDisabled ? (
                <div
                  style={{ color: "white", cursor: "text" }}
                  className="deactive_link"
                >
                  <img src={item.icon} alt={item.name} /> {item.name}
                </div>
              ) : (
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    isActive ? "active-link" : "deactive_link"
                  }
                >
                  <img src={item.icon} alt={item.name} /> {item.name}
                </NavLink>
              )}
            </li>
          );
        })}
      </ul>

      <div className="logout" onClick={handleShow}>
        <p>
          {" "}
          <img src="/assets/img/icons/logout.svg" alt="" /> Logout
        </p>
      </div>
      <Modal show={logoutModalShow} centered onHide={handleHide}>
        <div className="common__modal">
          <div className="closeModal" onClick={handleHide}>
            <img src="assets/img/icons/close.svg" height={10} alt="" />
          </div>

          <Modal.Body>
            <h2>Are you sure you want to logout ?</h2>
            <div className="d-flex gap-2 justify-content-center">
              <Button variant="modal-secondary" onClick={handleHide}>
                No
              </Button>
              <Button variant="modal-primary" onClick={() => handleLogout()}>
                Yes
              </Button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </aside>
  );
}

export default Sidebar;
