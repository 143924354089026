import React from "react";
import Slider from "react-slick";
import { IMAGE_BASE_URL } from "../../../constants/configuration";
import { vehicle_exp } from "../../../components/web/config";

export default function VehicleExperience({ details }) {
  return (
    <div className="vehicle_experience">
      <div className="container">
        <div className="header">
          <h2 className="title">Vehicle Experience</h2>
          <div className="partners">
            Partners
            <blockquote className="logos">
              {details?.vehicle_partners?.partners?.map((ele, i) => {
                return (
                  <img
                    src={`${IMAGE_BASE_URL}${details?.vehicle_partners?.path}/${ele?.image}`}
                    alt={ele.alt}
                  />
                );
              })}
            </blockquote>
          </div>
        </div>
        {details?.vehicleinfo?.length ? (
          <Slider {...vehicle_exp}>
            {details?.vehicleinfo?.map((ele, index) => (
              <div className="vehicle_details" key={index}>
                <img
                  src={`${IMAGE_BASE_URL}${ele?.path}/${ele?.image}`}
                  alt={ele.image_alt}
                />
                <h2>
                  {ele?.title}
                  {ele?.is_package_cost_included == "1" && <span>*</span>}
                </h2>
              </div>
            ))}
          </Slider>
        ) : (
          <></>
        )}

        <div className="mandatory">
          <p>
            Vehicle with <span>*</span> is included in package
          </p>
        </div>
      </div>
    </div>
  );
}
