import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../constants/configuration";

export const fetchHomeData = async (callback) => {
  try {
    const response = await axios.get(`${API_BASE_URL}home`);
    if (response.data.status === true) {
      if (callback ) {
        callback(true, response.data.data);
      }
    } else {
      toast.error(response.message, {
        position: "bottom-center",
        autoClose: 3000,
      });
    }
  } catch (error) {
    console.error("Error fetching curated data:", error);
    toast.error("Something went wrong. Please try again later.", {
      position: "bottom-center",
      autoClose: 3000,
    });

    if (callback) {
      callback(false, null);
    }
  }
};

export const fetchAboutData = async (callback) => {
  try {
    const response = await axios.get(`${API_BASE_URL}about`);
    if (response.data.status === true) {
      if (callback ) {
        callback(true, response.data.data);
      }
    } else {
      toast.error(response.message, {
        position: "bottom-center",
        autoClose: 3000,
      });
    }
  } catch (error) {
    console.error("Error fetching curated data:", error);
    toast.error("Something went wrong. Please try again later.", {
      position: "bottom-center",
      autoClose: 3000,
    });

    if (callback) {
      callback(false, null);
    }
  }
};


export const fetchCuratedData = async (callback) => {
  try {
    const response = await axios.get(`${API_BASE_URL}curated`);
    if (response.data.status === true) {
      if (callback ) {
        callback(true, response.data.data);
      }
    } else {
      toast.error(response.message, {
        position: "bottom-center",
        autoClose: 3000,
      });
    }
  } catch (error) {
    console.error("Error fetching curated data:", error);
    toast.error("Something went wrong. Please try again later.", {
      position: "bottom-center",
      autoClose: 3000,
    });

    if (callback) {
      callback(false, null);
    }
  }
};

export const countryDetails = async (callback) => {
  try {
    const response = await axios.get(`${API_BASE_URL}getCountry`);
    if (response.data.status === true) {
      if (callback ) {
        callback(true, response.data.data);
      }
    } else {
      toast.error(response.message, {
        position: "bottom-center",
        autoClose: 3000,
      });
    }
  } catch (error) {
    console.error("Error fetching curated data:", error);
    toast.error("Something went wrong. Please try again later.", {
      position: "bottom-center",
      autoClose: 3000,
    });

    if (callback) {
      callback(false, null);
    }
  }
};


export const fetchTermsData = async (callback) => {
  try {
    const response = await axios.get(`${API_BASE_URL}terms`);
    if (response.data.status === true) {
      if (callback ) {
        callback(true, response.data.data);
      }
    } else {
      toast.error(response.message, {
        position: "bottom-center",
        autoClose: 3000,
      });
    }
  } catch (error) {
    console.error("Error fetching curated data:", error);
    toast.error("Something went wrong. Please try again later.", {
      position: "bottom-center",
      autoClose: 3000,
    });
    if (callback) {
      callback(false, null);
    }
  }
};



export const fetchCancelPolicyData = async (callback) => {
  try {
    const response = await axios.get(`${API_BASE_URL}cancellation`);
    if (response.data.status === true) {
      if (callback ) {
        callback(true, response.data.data);
      }
    } else {
      toast.error(response.message, {
        position: "bottom-center",
        autoClose: 3000,
      });
    }
  } catch (error) {
    console.error("Error fetching curated data:", error);
    toast.error("Something went wrong. Please try again later.", {
      position: "bottom-center",
      autoClose: 3000,
    });
    if (callback) {
      callback(false, null);
    }
  }
};

export const fetchPrivacyPolicy = async (callback) => {
  try {
    const response = await axios.get(`${API_BASE_URL}privacy`);
    if (response.data.status === true) {
      if (callback ) {
        callback(true, response.data.data);
      }
    } else {
      toast.error(response.message, {
        position: "bottom-center",
        autoClose: 3000,
      });
    }
  } catch (error) {
    console.error("Error fetching curated data:", error);
    toast.error("Something went wrong. Please try again later.", {
      position: "bottom-center",
      autoClose: 3000,
    });
    if (callback) {
      callback(false, null);
    }
  }
};


export const fetchBookingProcessData = async (callback) => {
    try {
      const response = await axios.get(`${API_BASE_URL}booking-process`);
      if (response.data.status === true) {
        if (callback ) {
          callback(true, response.data.data);
        }
      } else {
        toast.error(response.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error fetching curated data:", error);
      toast.error("Something went wrong. Please try again later.", {
        position: "bottom-center",
        autoClose: 3000,
      });
      if (callback) {
        callback(false, null);
      }
    }
};

export const submitEnquiryRequest = (data, cb) => {
  axios
    .post(`${API_BASE_URL}submitEnquiry`, data)
    .then((res) => {
      cb && cb(res.data);
    })
    .catch((err) => {
      toast.error("Something went wrong!");
      cb(err.response.data);
    });
};

export const fetchContactData = async (callback) => {
  try {
    const response = await axios.get(`${API_BASE_URL}contact`);
    if (response.data.status === true) {
      if (callback ) {
        callback(true, response.data.data);
      }
    } else {
      toast.error(response.message, {
        position: "bottom-center",
        autoClose: 3000,
      });
    }
  } catch (error) {
    console.error("Error fetching curated data:", error);
    toast.error("Something went wrong. Please try again later.", {
      position: "bottom-center",
      autoClose: 3000,
    });

    if (callback) {
      callback(false, null);
    }
  }
};

export const submitContact = (data, cb) => {
  axios
    .post(`${API_BASE_URL}submitEnquiry`, data)
    .then((res) => {
      cb && cb(res.data);
    })
    .catch((err) => {
      toast.error("Something went wrong!");
      cb(err.response.data);
    });
};

export const fetchBlogData = async (raw, callback) => {
  try {
    const response = await axios.post(`${API_BASE_URL}blogs`, raw);
    if (response.data.status === true) {
      if (callback) {
        callback(true, response.data);
      }
    } else {
      toast.error(response.data.message, {
        position: "bottom-center",
        autoClose: 3000,
      });
      if (callback) {
        callback(false, null)
      }
    }
  } catch (err) {
    console.error("Error fetching blog data:", err);
    toast.error("Something went wrong. Please try again later.", {
      position: "bottom-center",
      autoClose: 3000,
    });
    if (callback) {
      callback(false, null);
    }
  }
};

