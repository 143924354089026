import React, { useEffect, useState } from "react";
import { fetchTermsData } from "../../service/service";
import PolicyContent from "../../components/web/PolicyContent";

const TermsAndCondition = () => {
  const [termData, setTermsData] = useState([]);

  useEffect(() => {
    fetchTermsData((success, data) => {
      if (success) {
        console.log(data)
        setTermsData(data);
      }
    });
  }, []);


  return <PolicyContent contentData={termData} />;
};

export default TermsAndCondition;
