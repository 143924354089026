import React, { useEffect } from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { contact, general } from "../redux/commonActionCreator";
import {
  contact_details,
  general_details,
  general_path,
} from "../redux/commonReducer";
import { IMAGE_BASE_URL } from "../constants/configuration";

export default function Footer() {
  const dispatch = useDispatch();

  const contactData = useSelector(contact_details);
  const path = useSelector(general_path);
  const generalDetails = useSelector(general_details);

  useEffect(() => {
    dispatch(contact());
    dispatch(general());
  }, []);

  return (
    <div className="skyward__footer">
      <div className="container">
        <div className="footer_logo">
          <img
            src={`${IMAGE_BASE_URL}${path}/${generalDetails?.footer_logo}`}
            alt={generalDetails?.footer_logo_alt}
          />
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="footer__links">
          <div className="footer_widgets" style={{ color: "white" }}>
            <p
              dangerouslySetInnerHTML={{ __html: contactData?.address1 }}
              style={{ whiteSpace: "pre-wrap" }}
            ></p>
          </div>
          <div className="footer_widgets" style={{ color: "white" }}>
            <p
              dangerouslySetInnerHTML={{ __html: contactData?.address2 }}
              style={{ whiteSpace: "pre-wrap" }}
            ></p>
          </div>
          <div className="quick_links">
            <div className="navList">
              <p>
                <Link to={"/"}>Home</Link>
              </p>
              <p>
                <Link to={"/about"}>About</Link>
              </p>
            </div>
            <div className="navList">
              <p>
                {" "}
                <Link to={"/self-drive"}>Self Drive</Link>
              </p>
              <p>
                <Link to={"/guided-tours"}>Guided Tours</Link>
              </p>
            </div>
            <div className="navList">
              <p>
                <Link to={"/blogs"}>Blog</Link>
              </p>
              <p>
                <Link to={"/contact"}>Contact</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="social__links">
            <ul>
              {generalDetails?.social_media?.map((ele, i) => {
                return (
                  <li
                    onClick={() => {
                      window.open(
                        ele?.link?.startsWith("http")
                          ? ele.link
                          : `https://${ele.link}`,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }}
                  >
                    <img
                      src={`${IMAGE_BASE_URL}${path}/${ele?.media_icon}`}
                      alt={ele?.media_icon_alt}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="col-md-8 col-tab-12">
            <div className="privacy">
              <ul>
                <li>
                  <Link to="/terms-condition">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/cancel-policy">Cancellation Policy</Link>
                </li>
                <li>© SMART HATCH TECHNOLOGIES FZE</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
