import { useEffect, useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountsDetails,
  getBookingStatus,
} from "../../redux/adminActionCreator";
import { booking_status } from "../../redux/adminReducer";
import moment from "moment";
import { dueDateFinder } from "../../utils/helpers";
import { getBookingDetails } from "../../redux/commonActionCreator";

const Header = () => {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const details = localStorage.getItem("booking_details")
    ? JSON.parse(localStorage.getItem("booking_details"))
    : {};
  const bookingId = localStorage.getItem("booking_id");

  const bookingStatus = useSelector(booking_status) ?? {};
  const [displayMsg, setDisplayMsg] = useState("");

  useEffect(() => {
    dispatch(getAccountsDetails());
  }, []);

  useEffect(() => {
    if (bookingId) {
      dispatch(getBookingStatus(bookingId));
      dispatch(getBookingDetails(bookingId));
    }
  }, [bookingId]);

  useEffect(() => {
    if (
      bookingStatus["is_passenger_info"] ||
      (!bookingStatus["is_customer_engagement"] &&
        !bookingStatus["is_itinerary"] &&
        !bookingStatus["is_quotation"] &&
        !bookingStatus["is_payment"])
    ) {
      setDisplayMsg(
        `Please update passenger details to proceed with your booking `
      );
    }
    if (bookingStatus["is_customer_update_passenger_info"]) {
      setDisplayMsg(`Passenger info received! We'll be in touch soon.`);
    }
    if (bookingStatus["is_customer_engagement"]) {
      setDisplayMsg(`Engagement Summary is Ready`);
    }
    if (bookingStatus["is_itinerary"] && bookingStatus["is_quotation"]) {
      setDisplayMsg(`Please review the quotation`);
    }
    if (
      details?.bookingpayments?.milestone?.length &&
      bookingStatus["is_payment"]
    ) {
      let nextMilestone = dueDateFinder(details?.bookingpayments?.milestone);
      console.log(nextMilestone)
      if (nextMilestone) {
        setDisplayMsg(
          `Your ${nextMilestone.position} milestone payment is due on ${moment(
            new Date(nextMilestone?.date)
          ).format("Do MMM YYYY")}`
        );
      } else{
        setDisplayMsg("")
      }
    }
    if (bookingStatus["is_payment_completed"]) {
      setDisplayMsg(
        `Your journey starts on ${moment(
          new Date(details?.start_travel_date)
        ).format("DD MMM YYYY")}`
      );
    }
    if (details?.is_completed && !details?.is_reviewed) {
      setDisplayMsg(`Share Your Iceland Experience!`);
    }
    if (details?.is_completed && details?.is_reviewed) {
      setDisplayMsg(`Thank you for choosing us !`);
    }

    // if (bookingStatus["is_itinerary"]) {
    //   setDisplayMsg(`Please review your itinerary.`);
    // }
    // if (bookingStatus["is_vehicle_details"]) {
    //   setDisplayMsg(`Please review your vehicle details.`);
    // }
    // if (bookingStatus["is_quotation"]) {
    //   setDisplayMsg(`Please review the quotation`);
    // }
  }, [bookingStatus, details,details?.bookingpayments?.milestone]);

  useEffect(() => {
    const isMobile = window.innerWidth <= 995;
    if (isMobile) {
      setIsMenuOpen(true);
      document.body.classList.add("menu-open");
    }
    return () => {
      document.body.classList.remove("menu-open");
    };
  }, []);

  const handleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
  };

  return (
    <header className="header">
      {/* <div className="menu d-block d-md-none" onClick={handleMenu}><img src="/assets/img/admin/menu.svg" className="menu" alt="" /></div> */}

      <div className="header-left">
        <div className="menu" onClick={handleMenu}>
          {!isMenuOpen ? (
            <img src="/assets/img/admin/menu.svg" className="menu" alt="" />
          ) : (
            <img src="/assets/img/admin/menu.svg" className="menu" alt="" />
          )}
        </div>
        <h2>Welcome, {details?.traveller_info?.[0]?.first_name}!</h2>
      </div>
      <div className="header-middle">
        <img src="/assets/img/admin/info.svg" alt="" />
        <h4 dangerouslySetInnerHTML={{ __html: displayMsg }}></h4>
      </div>
      <div className="header-right">
        <h1>
          BOOKING NO <br />
          <span>{details?.booking_ref_id}</span>
        </h1>
      </div>
    </header>
  );
};

export default Header;
