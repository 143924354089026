import Slider from "react-slick";
import Fancybox from "./Fancybox";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import "./GallerySlider.scss";

export default function GallerySlider({ sliderFeatures, gallery, path }) {
  const galleryArray = gallery || [];
  const ITEMS_PER_ROW = 3;
  const nestedGallery = [];

  for (let i = 0; i < galleryArray.length; i += ITEMS_PER_ROW) {
    nestedGallery.push(galleryArray.slice(i, i + ITEMS_PER_ROW));
  }

  const getThumbnail = (url) => {
    const videoId = url.split("/").pop();
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  };

  return (
    console.log(nestedGallery?.[0]?.length,'tested'),
    <div className="gallery_slider">
      <Fancybox
        options={{
          Carousel: {
            infinite: false,
          },
        }}
      >
        <div className="curated_gallery">
          <Slider arrows={false} dots={nestedGallery.length > 1} autoplay={false} infinite={false}>
            {nestedGallery?.map((group, i) => (
              <div key={i} className="slide-item">
                <div className={`slider__images ${nestedGallery?.[0]?.length ==2 ? 'twoSlides' : ''}`}>
                  {group.map((ele, i) => (
                    <div key={i} className="slide-content">
                      {ele?.type === "video" ? (
                        <div
                          className="video"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                        >
                          <a data-fancybox="gallery" href={ele.image}>
                            <div className="video-container">
                              <div className="tag">{ele.alt}</div>
                              <img
                                src={getThumbnail(ele.image)}
                                alt={ele.alt}
                              />
                              <div className="play">
                                <img
                                  src="/assets/img/icons/play.svg"
                                  alt="Play"
                                />
                              </div>
                            </div>
                          </a>
                        </div>
                      ) : (
                        <div
                          className="image"
                          data-aos="fade-up"
                          data-aos-duration="2000"
                        >
                          <a
                            data-fancybox="gallery"
                            href={`${IMAGE_BASE_URL}${path}/${ele.image}`}
                          >
                            <div className="tag">{ele.alt}</div>
                            <img
                              src={`${IMAGE_BASE_URL}${path}/${ele.image}`}
                              alt={ele.alt}
                            />
                          </a>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Fancybox>
    </div>
  );
}
