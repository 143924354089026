import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  package_common_details,
  package_common_path,
  package_data,
  package_loader,
  package_page_details,
  searchData,
  searchGuestData,
} from "../../redux/commonReducer";
import PackageList from "./PackageList";
import {
  getPackages,
  packageCommonData,
} from "../../redux/commonActionCreator";
import moment from "moment";
import HomeSearch from "../../components/web/HomeSearch";
import { IMAGE_BASE_URL } from "../../constants/configuration";

export default function SelfDrive() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const localData = JSON.parse(localStorage.getItem("searchData"));
  // const LocalGuestData = JSON.parse(localStorage.getItem("searchGuestData"));
  const localData = useSelector(searchData);
  const LocalGuestData = useSelector(searchGuestData);

  const packageData = useSelector(package_data);
  const pageDetails = useSelector(package_page_details);
  const loader = useSelector(package_loader);
  const packageCommonDetails = useSelector(package_common_details);
  const packageCommonPath = useSelector(package_common_path);

  const [data, setData] = useState({
    page: 0,
    perPage: 8,
  });

  useEffect(() => {
    getSearchDataHandler(data);
    dispatch(packageCommonData());
  }, []);

  const getSearchDataHandler = (filter) => {
    let rawData = {
      page: filter.page,
      perPage: filter.perPage,
      id: localData?.departure?.[0]?._id ?? "",
      start_date: localData?.start_date
        ? moment(localData?.start_date).format("YYYY-MM-DD")
        : "",
      end_date: localData?.end_date
        ? moment(localData?.end_date).format("YYYY-MM-DD")
        : "",
      number_of_guest: {
        adults: LocalGuestData?.find((ele) => ele.key === "adults")?.count,
        age_5_12: LocalGuestData?.find((ele) => ele.key === "age_5_12")?.count,
        age_2_5: LocalGuestData?.find((ele) => ele.key === "age_2_5")?.count,
        age_less_2: LocalGuestData?.find((ele) => ele.key === "age_less_2")
          ?.count,
      },
      api_type: "self-drive",
    };
    dispatch(getPackages(rawData));
  };

  const handlePageChange = () => {
    let temp;
    temp = {
      page: data?.page,
      perPage: data?.perPage + 4,
    };
    setData({
      ...temp,
    });
    getSearchDataHandler(temp);
  };

  return (
    <>
      <section className="skyward__selfDrive pt-0">
        <img
          src={`${IMAGE_BASE_URL}${packageCommonPath}/${packageCommonDetails?.banner_image}`}
          alt={packageCommonDetails?.banner_image_alt}
          className="w-100 main"
        />
        <div className="search__contents">
          <h1>{packageCommonDetails?.banner_title}</h1>
          <p
            dangerouslySetInnerHTML={{
              __html: packageCommonDetails?.banner_description,
            }}
          ></p>
          <HomeSearch />
        </div>
      </section>

      <section className="self-drive__lists">
        <div className="container">
          <div className="row">
            <PackageList packageData={packageData} />
          </div>
          {pageDetails?.pages == 1 ? (
            <></>
          ) : (
            <div className="button_center">
              <button
                className="btn btn-primary"
                onClick={() => handlePageChange()}
              >
                VIEW MORE <img src="assets/img/icons/button-arrow.svg" alt="" />
              </button>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
