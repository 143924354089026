import React, { useEffect } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { activity_details, activity_path } from "../../redux/commonReducer";
import { getActivityDetails } from "../../redux/commonActionCreator";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import { activity } from "../../components/web/config";

export default function ActivityDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id,packageId } = useParams();

  const details = useSelector(activity_details);
  const path = useSelector(activity_path);

  useEffect(() => {
   
    if (id ) {
      if(packageId){
       const row = {
          id,
          packageId,
        }
      dispatch(getActivityDetails(row));
      }else{
        dispatch(getActivityDetails(id));
      }
    }
    
  }, [id]);

  return (
    <div className="section__activities">
      <img
        className="main"
        src={`${IMAGE_BASE_URL}${path}/${details?.image}`}
        alt={details?.image_alt}
      />
      <div className="container">
        <div
          className={`tag ${details?.activities?.length ? "pointer" : ""}`}
          onClick={() =>
            details?.activities?.length
              ? navigate(
                  `/activity-details/${details?.activities?.[0].handle_key}`
                )
              : {}
          }
        >
          Activities
        </div>
        <h1 data-aos="fade-up" data-aos-duration="1000">
          {details?.title}
        </h1>
        <h6 data-aos="fade-up" data-aos-duration="1500">
          {" "}
          <img src="/assets/img/icons/clock.svg" alt="" /> Duration{" "}
          {details?.duration} hours
        </h6>
        <h2 data-aos="fade-up" data-aos-duration="1500">
          Details
        </h2>
        <p
          data-aos="fade-up"
          data-aos-duration="2000"
          dangerouslySetInnerHTML={{ __html: details?.long_description }}
        ></p>
      </div>
      {details?.activities?.length ? (
        <div className="more_activities">
          <div className="container">
            <h3>MORE ACTIVITIES</h3>
            <Slider {...activity}>
              {details?.activities?.map((ele, i) => {
                return (
                  <div
                    className="gridItem"
                    onClick={() =>
                      navigate(`/activity-details/${ele?.handle_key}`)
                    }
                  >
                    <img
                      src={`${IMAGE_BASE_URL}${path}/${ele?.image}`}
                      alt={ele.image_alt}
                    />
                    <h4>{ele?.title}</h4>
                    <h6>
                      {" "}
                      <img
                        src="/assets/img/icons/clock.svg"
                        alt=""
                      /> Duration {ele?.duration} hours
                    </h6>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
