import { useSelector } from "react-redux";
import {
  currency_data,
  currencyValue,
  package_path,
} from "../../redux/commonReducer";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import { CurrencyConversion } from "../../utils/helpers";
import { useEffect, useState } from "react";
import DynamicFontSize from "../../components/web/DynamicFontSize";
import StarRatings from "react-star-ratings";

const PackageList = ({ packageData }) => {
  const path = useSelector(package_path);
  const currencyData = useSelector(currency_data);
  const selectedCurrency = useSelector(currencyValue);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (packageData && currencyData) {
      setData(
        packageData?.map((item, i) => {
          return {
            ...item,
            [`currency`]: currencyData?.find(
              (ele) => ele?.handle_key === "usd"
            ),
          };
        })
      );
    }
  }, [packageData, currencyData]);

  const onCurrencyChange = (value, i) => {
    let arr = [...data];
    let obj = { ...arr[i] };
    obj = {
      ...obj,
      currency: value,
    };
    arr[i] = obj;
    setData([...arr]);
  };

  return (
    <>
      {data?.map((ele, i) => {
        return (
          <div
            className="col-md-3 col-tab-2 p-2"
            data-aos="fade-up"
            data-aos-duration={`${i}000`}
            key={i}
          >
            <div className="package">
              <div className="img">
                <div className="type">
                  <img
                    src={`${IMAGE_BASE_URL}${ele?.season?.path}/${ele?.season?.icon}`}
                    alt={ele.season?.icon_alt}
                  />
                </div>
                <img
                  src={`${IMAGE_BASE_URL}${path ?? "package"}/${ele?.thumbnail
                    }`}
                  alt={ele.thumbnail_alt}
                />
                <div className="tag">SELF DRIVE</div>
              </div>
              {ele?.averageRating ? (
                <div className="rating">
                  <ul>
                    <StarRatings
                      rating={Number(ele?.averageRating) ?? 0}
                      starRatedColor="#dc1e35"
                      numberOfStars={5}
                      starEmptyColor="#e3e3e3"
                      name="rating"
                      starDimension="22px"
                      starSpacing="2px"
                    />
                  </ul>
                  <span>{ele?.averageRating}/5 rating</span>
                </div>
              ) : (
                <></>
              )}

              <div className={`days ${ele?.averageRating ? "" : "mt-3"}`}>
                {`${ele?.duration} ${ele?.duration === 1 ? "day" : "days"}`}
              </div>

              <h2>{ele?.package_name}</h2>
              <div className="pricing">
                <span>From</span>
                <div className="price">
                  <DynamicFontSize
                    number={
                      CurrencyConversion(selectedCurrency, ele?.adult_cost) ??
                      ele?.adult_cost
                    }
                  />

                  {/* For the currency code */}
                  <DynamicFontSize
                    number={selectedCurrency?.currency_code ?? "USD"}
                    tag="h5"
                    className="mb-0"
                  />
                </div>
                <p>Price per person</p>
              </div>
              <p className="exclude">* Excluding Flight Cost</p>
              <div className="view_trip">
                <Link to={`/package-details/${ele?.handle_key}`}>
                  view trip <img src="/assets/img/icons/btn-arrow.svg" alt="" />
                </Link>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default PackageList;
