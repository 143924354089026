import React, { useEffect, useState } from "react";
import "./home.css";
import { useDispatch, useSelector } from "react-redux";
import { getBookingDetails } from "../../../redux/commonActionCreator";
import { booking_details } from "../../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../../constants/configuration";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { booking_status } from "../../../redux/adminReducer";
import { Modal } from "react-bootstrap";
import { getBookingStatus } from "../../../redux/adminActionCreator";
import { dueDateFinder } from "../../../utils/helpers";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const bookingId = localStorage.getItem("booking_id");

  const details = useSelector(booking_details);
  const bookingStatus = useSelector(booking_status) ?? {};

  const [openModal, setOpenModal] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [displayTitle, setDisplayTitle] = useState("");
  const [displayMsg, setDisplayMsg] = useState("");

  useEffect(() => {
    if (bookingId) {
      dispatch(getBookingDetails(bookingId));
      dispatch(getBookingStatus(bookingId));
    }
  }, [bookingId]);

  useEffect(() => {
    if (details?.is_completed) {
      setCompleted(true);
      if (!details?.is_reviewed) {
        setOpenModal(true);
      }
    }
  }, [details]);

  useEffect(() => {
    if (
      bookingStatus["is_passenger_info"] ||
      (!bookingStatus["is_customer_engagement"] &&
        !bookingStatus["is_itinerary"] &&
        !bookingStatus["is_quotation"] &&
        !bookingStatus["is_payment"])
    ) {
      setDisplayTitle("Thank You For Your Token Payment");
      setDisplayMsg(
        `We're excited to have you on board! Rest assured, We'll guide you through the next steps and make your iceland trip unforgettable`
      );
    }
    if (bookingStatus["is_customer_update_passenger_info"]) {
      setDisplayTitle("Thank you for submitting your passenger information!");
      setDisplayMsg(
        `We're excited to start crafting your perfect Iceland itinerary. One of our representatives will be in touch soon for a customer engagement session to discuss your preferences and finalize the details of your adventure.`
      );
    }
    if (bookingStatus["is_customer_engagement"]) {
      setDisplayTitle("");
      setDisplayMsg(
        `We've prepared a summary of our recent conversation about your travel plans.`
      );
    }
    if (bookingStatus["is_itinerary"] && bookingStatus["is_quotation"]) {
      setDisplayTitle("");
      setDisplayMsg(
        `We've created your personalized Iceland itinerary and quotation based on our discussions.`
      );
    }
    if (
      details?.bookingpayments?.milestone?.length &&
      bookingStatus["is_payment"]
    ) {
      let nextMilestone = dueDateFinder(details?.bookingpayments?.milestone);
      if (nextMilestone) {
        setDisplayTitle("Milestone payment is Due on !");
        setDisplayMsg(
          `Your ${nextMilestone.position} milestone payment is due on ${moment(
            new Date(nextMilestone?.date)
          ).format("Do MMM YYYY")}`
        );
      } else {
        setDisplayTitle("");
        setDisplayMsg("");
      }
    }
    if (bookingStatus["is_payment_completed"]) {
      setDisplayTitle("Get ready for your Iceland adventure!");
      setDisplayMsg(
        `Your trip is just around the corner. You can now access all your essential travel documents, including flight confirmations, hotel bookings, and itinerary details, in the "Documents" section of your account.`
      );
    }
    if (details?.is_completed && !details?.is_reviewed) {
      setDisplayTitle("");
      setDisplayMsg(
        `We hope you had an incredible time exploring Iceland with Skyward Xperia! Now that you're back, we'd love to hear about your experience`
      );
    }
    if (details?.is_completed && details?.is_reviewed) {
      setDisplayTitle("");
      setDisplayMsg(
        `We truly appreciate you taking the time to share your experience. Your review helps us improve and inspire other travelers. We look forward to welcoming you again on your next adventure`
      );
    }
    // if (bookingStatus["is_itinerary"]) {
    //   setDisplayTitle("");
    //   setDisplayMsg(``);
    // }
    // if (bookingStatus["is_vehicle_details"]) {
    //   setDisplayTitle("");
    //   setDisplayMsg(``);
    // }
    // if (bookingStatus["is_quotation"]) {
    //   setDisplayTitle("");
    //   setDisplayMsg(``);
    // }
    // if (bookingStatus["is_payment"]) {
    //   setDisplayTitle("");
    //   setDisplayMsg("");
    // }
  }, [bookingStatus, details]);

  const onClickHandler = () => {
    if (
      bookingStatus["is_passenger_info"] ||
      (!bookingStatus["is_customer_engagement"] &&
        !bookingStatus["is_quotation"] &&
        !bookingStatus["is_payment"])
    ) {
      navigate("/traveller-details");
    }
    if (bookingStatus["is_customer_engagement"]) {
      navigate("/engagement-details");
    }
    if (bookingStatus["is_itinerary"]) {
      navigate("/itinerary");
    }
    if (bookingStatus["is_vehicle_details"]) {
      navigate("/itinerary");
    }
    if (bookingStatus["is_quotation"]) {
      navigate("/payments");
    }
    if (bookingStatus["is_payment"]) {
      navigate("/payments");
    }
  };

  const onReviewHandler = () => {
    let url = `${window.location.origin}/package-details/${details?.bookingitinerary?.handle_key}/${bookingId}`;
    console.log(url);
    window.open(url, "_blank");
  };

  return (
    <main className="main-container">
      <div className="container">
        <div className="row">
          <div className="col-12 py-3 py-md-0">
            <div class="card">
              <div class="card-body">
                <h3>
                  {/* {completed
                    ? "Thank you for traveling with us"
                    : "Thank You For Your Token Payment"} */}
                  {displayTitle}
                </h3>
                <div className="card-description">
                  <p>{displayMsg}</p>
                  {details?.is_reviewed ? (
                    <></>
                  ) : (
                    <button
                      onClick={() =>
                        completed ? onReviewHandler() : onClickHandler()
                      }
                    >
                      {completed ? "Review" : "Click Here"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="banner-show">
        <div className="container">
          <div className="card">
            <div className="row">
              <div className="col-md-6 py-3 py-md-0 selected_package">
                <img
                  className="card-with-gradient card-image"
                  src={`${IMAGE_BASE_URL}${"package"}/${
                    details?.bookingitinerary?.thumbnail
                  }`}
                  alt={details?.bookingitinerary?.thumbnail_alt}
                />
                <div className="selected_package_btn">
                  <button>Selected Package</button>
                </div>
              </div>
              <div className="col-md-6 py-3 py-md-0">
                <div className="banner-txt">
                  <h2>{details?.bookingitinerary?.package_name}</h2>
                </div>
                <div className="icons-list">
                  <div className="icons-container">
                    <img src="/assets/img/admin/self-drive.svg" alt="" />
                    <p>SELF DRIVE</p>
                  </div>
                  <div className="icons-container">
                    <img
                      src={`${IMAGE_BASE_URL}${details?.bookingitinerary?.season?.path}/${details?.bookingitinerary?.season?.icon}`}
                      alt={details?.bookingitinerary?.season?.icon_alt}
                    />

                    <p>{details?.bookingitinerary?.season?.name}</p>
                  </div>
                  <div className="icons-container">
                    <img src="/assets/img/admin/date.svg" alt="" />
                    <p>
                      {/* {details?.start_month
                        ? `${details?.start_month} - ${details?.end_month}`
                        : "Apr- Oct"} */}
                      {`${
                        details?.bookingitinerary?.season?.season_months?.[0]
                      } - ${
                        details?.bookingitinerary?.season?.season_months?.[
                          details?.bookingitinerary?.season?.season_months
                            ?.length - 1
                        ]
                      }`}
                    </p>
                  </div>

                  {details?.bookingitinerary?.klm ? (
                    <>
                      <div className="icons-container">
                        <img
                          className="image-single"
                          style={{ height: "34px", objectFit: "contain" }}
                          src="/assets/img/admin/km.svg"
                          alt=""
                        />
                      </div>
                      <div className="icons-container">
                        <span>{details?.bookingitinerary?.klm} km</span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="members-details">
                  <div className="members-list">
                    <p>Adults</p>
                    <span>{details?.no_of_adults}</span>
                  </div>
                  <div className="members-list">
                    <p>Children</p>
                    <span>{details?.no_of_childrens}</span>
                  </div>
                  <div className="members-list">
                    <p>Duration</p>
                    <span>
                      {details?.travel_duration}{" "}
                      {details?.travel_duration === 1 ? "Day" : "Days"}
                    </span>
                  </div>
                </div>
                {details?.bookingitinerary?.flight_booking &&
                details?.flight_outbound?.aircraft_details?.length ? (
                  <>
                    <div className="fly-container">
                      <div className="fly_container_division">
                        <div className="fly-content">
                          <p>{details?.flight_outbound?.departure_airport}</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="310.469"
                            height="19.813"
                            viewBox="0 0 482.469 16.813"
                          >
                            <defs>
                              <clipPath id="clip-path">
                                <rect
                                  id="Rectangle_22756"
                                  data-name="Rectangle 22756"
                                  width="17.74"
                                  height="16.813"
                                  fill="#072439"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Group_41575"
                              data-name="Group 41575"
                              transform="translate(-1138.512 -660.504)"
                            >
                              <g
                                id="Group_40449"
                                data-name="Group 40449"
                                transform="translate(1138.512 669.461)"
                              >
                                <line
                                  id="Line_456"
                                  data-name="Line 456"
                                  x2="482.469"
                                  transform="translate(0 0)"
                                  fill="none"
                                  stroke="#072439"
                                  stroke-width="1"
                                  stroke-dasharray="4"
                                />
                              </g>
                              <g
                                id="Group_40971"
                                data-name="Group 40971"
                                transform="translate(1378.742 660.504)"
                              >
                                <g
                                  id="Group_40400"
                                  data-name="Group 40400"
                                  clip-path="url(#clip-path)"
                                >
                                  <path
                                    id="Path_78466"
                                    data-name="Path 78466"
                                    d="M17.74,8.641c-.015.036-.032.071-.043.107a1.442,1.442,0,0,1-1.494,1.1c-1.367-.024-2.735-.051-4.1-.092a.374.374,0,0,0-.4.253c-1.093,2.193-2.2,4.38-3.292,6.572a.35.35,0,0,1-.372.227c-.647-.013-1.295,0-1.97,0L7.756,9.693c-.063-.008-.113-.018-.162-.019C6.2,9.643,4.812,9.617,3.422,9.58a.376.376,0,0,0-.362.189c-.306.444-.629.875-.937,1.317a.348.348,0,0,1-.326.173c-.585-.009-1.171,0-1.8,0,.043-.1.073-.176.108-.249.385-.8.768-1.6,1.16-2.4a.4.4,0,0,0,0-.394C.88,7.419.5,6.623.118,5.828.081,5.753.051,5.675,0,5.558c.369,0,.708,0,1.047,0a2.251,2.251,0,0,1,.922.041c.249.127.39.469.575.72.167.226.338.451.489.687a.44.44,0,0,0,.436.228c1.367-.04,2.734-.064,4.1-.094.054,0,.108-.007.187-.012L6.068.019c.087-.005.16-.014.233-.014C6.887,0,7.473.012,8.059,0A.316.316,0,0,1,8.4.207c1.1,2.206,2.212,4.408,3.314,6.614a.344.344,0,0,0,.368.231c1.281-.037,2.563-.037,3.843-.1A1.583,1.583,0,0,1,17.74,8.172Z"
                                    transform="translate(0 -0.001)"
                                    fill="#072439"
                                  />
                                </g>
                              </g>
                            </g>
                          </svg>
                          <p>{details?.flight_outbound?.arrival_airport}</p>
                        </div>
                        <p className="fly-shortdescription">
                          {details?.flight_outbound?.stop_summary}
                        </p>
                        <div className="time-description">
                          <p>
                            {details?.flight_outbound?.segments
                              ?.map(
                                (ele, i) =>
                                  `${moment(ele?.departure_at?.date).format(
                                    "DD MMMM YYYY"
                                  )} (${ele?.origin} -${ele?.destination} : ${
                                    ele?.departure_at?.time
                                  } -${ele?.arrival_at?.time})`
                              )
                              .join(", ")}
                          </p>
                        </div>
                      </div>
                      <div className="image-content">
                        <div className="fly-img">
                          {details?.flight_outbound?.aircraft_details?.map(
                            (ele, index) => {
                              return (
                                <div className="fly-border">
                                  <img src={ele?.logo} alt="" />
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="fly-container">
                      <div className="fly_container_division">
                        <div className="fly-content">
                          <p>{details?.flight_return?.departure_airport}</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="310.469"
                            height="19.813"
                            viewBox="0 0 482.469 16.813"
                          >
                            <defs>
                              <clipPath id="clip-path">
                                <rect
                                  id="Rectangle_22756"
                                  data-name="Rectangle 22756"
                                  width="17.74"
                                  height="16.813"
                                  fill="#072439"
                                />
                              </clipPath>
                            </defs>
                            <g
                              id="Group_41575"
                              data-name="Group 41575"
                              transform="translate(-1138.512 -660.504)"
                            >
                              <g
                                id="Group_40449"
                                data-name="Group 40449"
                                transform="translate(1138.512 669.461)"
                              >
                                <line
                                  id="Line_456"
                                  data-name="Line 456"
                                  x2="482.469"
                                  transform="translate(0 0)"
                                  fill="none"
                                  stroke="#072439"
                                  stroke-width="1"
                                  stroke-dasharray="4"
                                />
                              </g>
                              <g
                                id="Group_40971"
                                data-name="Group 40971"
                                transform="translate(1378.742 660.504)"
                              >
                                <g
                                  id="Group_40400"
                                  data-name="Group 40400"
                                  clip-path="url(#clip-path)"
                                >
                                  <path
                                    id="Path_78466"
                                    data-name="Path 78466"
                                    d="M17.74,8.641c-.015.036-.032.071-.043.107a1.442,1.442,0,0,1-1.494,1.1c-1.367-.024-2.735-.051-4.1-.092a.374.374,0,0,0-.4.253c-1.093,2.193-2.2,4.38-3.292,6.572a.35.35,0,0,1-.372.227c-.647-.013-1.295,0-1.97,0L7.756,9.693c-.063-.008-.113-.018-.162-.019C6.2,9.643,4.812,9.617,3.422,9.58a.376.376,0,0,0-.362.189c-.306.444-.629.875-.937,1.317a.348.348,0,0,1-.326.173c-.585-.009-1.171,0-1.8,0,.043-.1.073-.176.108-.249.385-.8.768-1.6,1.16-2.4a.4.4,0,0,0,0-.394C.88,7.419.5,6.623.118,5.828.081,5.753.051,5.675,0,5.558c.369,0,.708,0,1.047,0a2.251,2.251,0,0,1,.922.041c.249.127.39.469.575.72.167.226.338.451.489.687a.44.44,0,0,0,.436.228c1.367-.04,2.734-.064,4.1-.094.054,0,.108-.007.187-.012L6.068.019c.087-.005.16-.014.233-.014C6.887,0,7.473.012,8.059,0A.316.316,0,0,1,8.4.207c1.1,2.206,2.212,4.408,3.314,6.614a.344.344,0,0,0,.368.231c1.281-.037,2.563-.037,3.843-.1A1.583,1.583,0,0,1,17.74,8.172Z"
                                    transform="translate(0 -0.001)"
                                    fill="#072439"
                                  />
                                </g>
                              </g>
                            </g>
                          </svg>
                          <p>{details?.flight_return?.arrival_airport}</p>
                        </div>
                        <p className="fly-shortdescription">
                          {details?.flight_return?.stop_summary}
                        </p>
                        <div className="time-description">
                          <p>
                            {details?.flight_return?.segments
                              ?.map(
                                (ele, i) =>
                                  `${moment(ele?.departure_at?.date).format(
                                    "DD MMMM YYYY"
                                  )} (${ele?.origin} - ${ele?.destination} : ${
                                    ele?.departure_at?.time
                                  } - ${ele?.arrival_at?.time})`
                              )
                              .join(", ")}
                          </p>
                        </div>
                      </div>
                      <div className="image-content">
                        <div className="fly-img">
                          {details?.flight_return?.aircraft_details?.map(
                            (ele, index) => {
                              return (
                                <div className="fly-border">
                                  <img src={ele?.logo} alt="" />
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div
                  className={
                    details?.bookingitinerary?.flight_booking &&
                    details?.flight_outbound?.aircraft_details?.length
                      ? "amount_description"
                      : "amount_without_flight"
                  }
                >
                  <div className="total_content">
                    <h4>Grand Total</h4>
                    <div className="ps-2">
                      <div className="price_description">
                        <h3>
                          {details?.bookingitinerary?.adult_cost?.toFixed(2)}{" "}
                        </h3>
                        <p className="currency">
                          USD
                          <span className="per-description">X Per Person</span>
                        </p>
                      </div>
                      <p className="amount_usd">
                        total of{" "}
                        {details?.bookingitinerary?.total_estimate?.toFixed(2)}{" "}
                        USD <span>( Approx.)</span>
                      </p>
                    </div>
                  </div>
                  <div className="stay-content">
                    <p>
                      We're Finalizing Your <br /> Personalized Quote.
                    </p>
                    <h4>STAY TUNED</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overview-content">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <div className="col-12 py-3 py-md-0">
                <div className="overview-txt">
                  <h1>Overview</h1>
                </div>
                <div className="payment-card mt-2">
                  <div className="card">
                    <div className="card-content">
                      <div className="card-items">
                        <img src="/assets/img/admin/token.svg" alt="" />

                        <div className="card-txt">
                          <h6>Token Payment</h6>
                          <div className="booking-details">
                            <div className="booking-content">
                              <p>Transaction Id</p>
                              <p>Token Date</p>
                              <p>Token Amount</p>
                            </div>
                            <div className="booking-content">
                              <p>{details?.bookingpayments?.txt_id}</p>
                              <p>
                                {moment(
                                  details?.bookingpayments?.paymentdate_date
                                ).format("DD MMM YYYY")}
                              </p>
                              <p>{details?.bookingpayments?.amount} USD</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-button">
                        <button className="btn-paid">Paid</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="payment-card mt-3">
                  <div className="card">
                    <div className="card-content">
                      <div className="card-items">
                        <img
                          src="/assets/img/admin/traveller_overview.svg"
                          alt=""
                        />
                        <div className="card-txt">
                          <h6>Traveller Information</h6>

                          <div className="booking-content">
                            <p>
                              Please update passenger details to proceed with
                              your booking
                            </p>
                          </div>
                          <div className="btn-travel mt-2">
                            <button
                              onClick={() => navigate("/traveller-details")}
                            >
                              Click Here
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card-button">
                        <button
                          className={`${
                            bookingStatus["is_customer_update_passenger_info"]
                              ? "btn-paid"
                              : "btn-pending"
                          }`}
                        >
                          {bookingStatus["is_customer_update_passenger_info"]
                            ? "Completed"
                            : "Pending"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="payment-card mt-3">
                  <div className="card">
                    <div className="card-content">
                      <div className="card-items">
                        <img src="/assets/img/admin/customer.svg" alt="" />
                        <div className="card-txt">
                          <h6>Customer Engagement</h6>

                          <div className="booking-content">
                            <p>
                              We're Excited To Connect With You And Discuss Your
                              Travel Preferences. Our Team Will <br /> Reach Out
                              Soon To Schedule A Call.
                            </p>
                          </div>
                          {bookingStatus["is_customer_engagement"] ? (
                            <div className="btn-travel mt-2">
                              <button
                                onClick={() =>
                                  bookingStatus["is_customer_engagement"]
                                    ? navigate("/engagement-details")
                                    : {}
                                }
                                disabled={
                                  !bookingStatus["is_customer_engagement"]
                                }
                              >
                                Click Here
                              </button>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="card-button">
                        <button
                          className={`${
                            bookingStatus["is_customer_engagement"]
                              ? "btn-paid"
                              : "btn-pending"
                          }`}
                          disabled={!bookingStatus["is_customer_engagement"]}
                        >
                          {bookingStatus["is_customer_engagement"]
                            ? "Completed"
                            : "Pending"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="payment-card mt-3">
                  <div className="card">
                    <div className="card-content">
                      <div className="card-items">
                        <img src="/assets/img/admin/finalized.svg" alt="" />
                        <div className="card-txt">
                          <h6>Finalized Quote</h6>

                          <div className="booking-content">
                            <p>
                              Your Final, Personalized Quote Will Be Shared With
                              You Once We've <br /> Completed Our Customer
                              Engagement Process.
                            </p>
                          </div>
                          {bookingStatus["is_quotation"] ? (
                            <div className="btn-travel mt-2">
                              <button
                                onClick={() =>
                                  bookingStatus["is_quotation"]
                                    ? navigate("/payments")
                                    : {}
                                }
                                disabled={!bookingStatus["is_quotation"]}
                              >
                                Click Here
                              </button>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="card-button">
                        <button
                          className={`${
                            bookingStatus["is_quotation"]
                              ? "btn-paid"
                              : "btn-pending"
                          }`}
                        >
                          {bookingStatus["is_quotation"]
                            ? "Completed"
                            : "Pending"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        show={openModal}
        centered
        onHide={() => setOpenModal(false)}
      >
        <Modal.Body className="mb-5 mt-4 ">
          <div className="travel_modal travel_completed">
            <img
              src="/assets/img/admin/travel-success.png"
              className="w-100"
              alt=""
            />
            <h2>Travel Completed!</h2>
            <p>Your travel completed rate you experiance</p>
            <button
              className="btn btn-secondary"
              onClick={() => onReviewHandler()}
            >
              Rate Us
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default Home;
