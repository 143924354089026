import React, { useEffect, useState } from "react";
import { fetchCancelPolicyData } from "../../service/service";
import PolicyContent from "../../components/web/PolicyContent";

export default function CancelPolicy() {
  const [cancelPolicyData, setCancelPolicy] = useState({});

  useEffect(() => {
    fetchCancelPolicyData((success, data) => {
      if (success) {
        setCancelPolicy(data);
      }
    });
  }, []);


  return <PolicyContent contentData={cancelPolicyData} />;
}
