import React, { useEffect, useState } from "react";
import "./documents.css";
import Accordion from "react-bootstrap/Accordion";
import { getBookingItinerary } from "../../../redux/adminActionCreator";
import { useDispatch, useSelector } from "react-redux";
import {
  customer_itinerary_details,
  customer_itinerary_loading,
} from "../../../redux/adminReducer";
import { getDateRange } from "../../../utils/helpers";
import { IMAGE_BASE_URL } from "../../../constants/configuration";
import AttachmentContent from "../../../commonComponents/AttachmentContent/AttachmentContent";
import moment from "moment";

const Documents = () => {
  const dispatch = useDispatch();
  const bookingId = localStorage.getItem("booking_id");

  const details = useSelector(customer_itinerary_details);
  const loading = useSelector(customer_itinerary_loading);

  const [activeKey, setActiveKey] = useState(0);
  const [itinerary, setItinerary] = useState([]);
  const [dateArray, setDateArray] = useState([]);
  const [activeKeyVehicle, setActiveKeyVehicle] = useState(0);

  useEffect(() => {
    if (bookingId) {
      dispatch(getBookingItinerary(bookingId));
    }
  }, [bookingId]);

  useEffect(() => {
    if (details?.bookingitinerary?.length) {
      const mutableArray = details.bookingitinerary.map((item) => ({
        ...item,
      }));
      setItinerary(mutableArray);
    }
    if (details?.start_travel_date && details?.end_travel_date) {
      let dateArrayList = getDateRange(
        details?.start_travel_date,
        details?.end_travel_date
      );
      setDateArray(dateArrayList);
    }
  }, [details]);

  return (
    <section id="documents">
      <div className="documents_header">
        <h4>Car & Flights Documents</h4>
      </div>
      {details?.pickup_attachments?.length ? (
        <div className="container px-4">
          <div className="row">
            <div className="col-12 py-3">
              <div className="document_card py-2">
                <div className="card-body p-3">
                  <h5 className="accordion_header mb-3">Pick-up Details</h5>
                  <AttachmentContent
                    label={""}
                    files={details?.pickup_attachments?.map((ele, i) => {
                      return {
                        name: ele,
                        type: ele?.split(".").pop(),
                        url: `${IMAGE_BASE_URL}${"booking"}/${ele}`,
                      };
                    })}
                  />
                  <div className="row mt-3">
                    <div className="col-6 col-md-3 ">
                      <p className="passenger_label">Pick up location</p>
                      <p className="passenger_Details">
                        {details?.pick_up_location}
                      </p>
                    </div>
                    <div className="col-6 col-md-3 ">
                      <p className="passenger_label">Pick up date and time</p>
                      <p className="passenger_Details">
                        {moment(details?.pick_up_date).format("DD MMM YYYY")}, {details?.pick_up_time}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {details?.dropoff_attachments?.length ? (
        <div className="container px-4">
          <div className="row">
            <div className="col-12 py-3">
              <div className="document_card py-2">
                <div className="card-body p-3">
                  <h5 className="accordion_header mb-3">
                    Drop-off Details
                  </h5>
                  <AttachmentContent
                    label={""}
                    files={details?.dropoff_attachments?.map((ele, i) => {
                      return {
                        name: ele,
                        type: ele?.split(".").pop(),
                        url: `${IMAGE_BASE_URL}${"booking"}/${ele}`,
                      };
                    })}
                  />
                  <div className="row mt-3">
                    <div className="col-6 col-md-3">
                      <p className="passenger_label">Drop off location</p>
                      <p className="passenger_Details">{details?.drop_off_location}</p>
                    </div>

                    <div className="col-6 col-md-3">
                      <p className="passenger_label">Drop off date and time</p>
                      <p className="passenger_Details">
                        {moment(details?.drop_off_date).format("DD MMM YYYY")}, {details?.drop_off_time}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {details?.flight_starttrip_attachments?.length ? (
        <div className="container px-4">
          <div className="row">
            <div className="col-12 py-3">
              <div className="document_card py-2">
                <div className="card-body p-3">
                  <h6 className="accordion_header mb-3">
                    Flight Start Trip Details
                  </h6>
                  <AttachmentContent
                    label={""}
                    files={details?.flight_starttrip_attachments?.map(
                      (ele, i) => {
                        return {
                          name: ele,
                          type: ele?.split(".").pop(),
                          url: `${IMAGE_BASE_URL}${"booking"}/${ele}`,
                        };
                      }
                    )}
                  />
                  <div className="row mt-3">
                    <div className="col-6 col-md-3 mb-3">
                      <p className="passenger_label">Departure Airport</p>
                      <p className="passenger_Details">{details?.start_departure_airport}</p>
                    </div>

                    <div className="col-6 col-md-3 mb-3">
                      <p className="passenger_label">Flight Start Date and Time</p>
                      <p className="passenger_Details">
                        {moment(details?.flight_start_date).format("DD MMM YYYY")}, {details?.flight_start_time}
                      </p>
                    </div>

                    <div className="col-6 col-md-3 mb-3">
                      <p className="passenger_label">Arrival Airport</p>
                      <p className="passenger_Details">{details?.start_arrival_airport}</p>
                    </div>

                    <div className="col-6 col-md-3 mb-3">
                      <p className="passenger_label">Arrival Date and Time</p>
                      <p className="passenger_Details">
                        {moment(details?.arrival_start_date).format("DD MMM YYYY")}, {details?.arrival_start_time}
                      </p>
                    </div>

                    <div className="col-6 col-md-3 mb-3">
                      <p className="passenger_label">Travel Time</p>
                      <p className="passenger_Details">{details?.start_travel_time}</p>
                    </div>

                    <div className="col-6 col-md-3 mb-3">
                      <p className="passenger_label">Flight Stops</p>
                      <p className="passenger_Details">{details?.start_flight_stops}</p>
                    </div>

                    <div className="col-6 col-md-3 mb-3">
                      <p className="passenger_label">Flight Description</p>
                      <p className="passenger_Details">{details?.start_flight_description}</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {details?.flight_returntrip_attachments?.length ? (
        <div className="container px-4">
          <div className="row">
            <div className="col-12 py-3">
              <div className="document_card py-2">
                <div className="card-body p-3">
                  <h6 className="accordion_header mb-3">
                    Flight Return Trip Details
                  </h6>
                  <AttachmentContent
                    label={""}
                    files={details?.flight_returntrip_attachments?.map(
                      (ele, i) => {
                        return {
                          name: ele,
                          type: ele?.split(".").pop(),
                          url: `${IMAGE_BASE_URL}${"booking"}/${ele}`,
                        };
                      }
                    )}
                  />
                  <div className="row mt-3">

                    <div className="col-6 col-md-3 mb-3">
                      <p className="passenger_label"> Departure Airport</p>
                      <p className="passenger_Details">{details?.return_departure_airport}</p>
                    </div>


                    <div className="col-6 col-md-3 mb-3">
                      <p className="passenger_label">Flight Date and Time</p>
                      <p className="passenger_Details">
                        {moment(details?.flight_return_date).format("DD MMM YYYY")}, {details?.flight_return_time}
                      </p>
                    </div>


                    <div className="col-6 col-md-3 mb-3">
                      <p className="passenger_label"> Arrival Airport</p>
                      <p className="passenger_Details">{details?.return_arrival_airport}</p>
                    </div>


                    <div className="col-6 col-md-3 mb-3">
                      <p className="passenger_label"> Arrival Date and Time</p>
                      <p className="passenger_Details">
                        {moment(details?.return_arrival_start_date).format("DD MMM YYYY")}, {details?.return_arrival_start_time}
                      </p>
                    </div>


                    <div className="col-6 col-md-3 mb-3">
                      <p className="passenger_label"> Travel Time</p>
                      <p className="passenger_Details">{details?.return_travel_time}</p>
                    </div>


                    <div className="col-6 col-md-3 mb-3">
                      <p className="passenger_label">Flight Stops</p>
                      <p className="passenger_Details">{details?.return_flight_stops}</p>
                    </div>


                    <div className="col-6 col-md-3 mb-3">
                      <p className="passenger_label"> Flight Description</p>
                      <p className="passenger_Details">{details?.return_flight_description}</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="documents_header">
        <h4>Essential Documents</h4>
      </div>
      <div className="container px-4">
        <div className="row">
          <div className="col-12 py-3">
            <div className="document_card py-2">
              <div className="card-body p-3">
                <Accordion defaultActiveKey="0" flush activeKey={activeKey}>
                  {itinerary?.map((ele, index) => {
                    return (
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header
                          onClick={() =>
                            setActiveKey(activeKey === index ? null : index)
                          }
                        >
                          <div className="itinerary_header_content">
                            <h6 className="accordion_header">
                              Day {index + 1} : {ele?.title}
                            </h6>
                            <div className="me-4 itinerary_date">
                              <p className="mb-0">{dateArray[index]}</p>
                            </div>
                          </div>
                        </Accordion.Header>

                        <Accordion.Body>
                          <Accordion.Body>
                            {ele?.attachments?.length ? (
                              <>
                                {ele?.attachments?.map((item, i) => {
                                  return (
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                      <div className="d-flex gap-3 align-items-center">
                                        {item?.type == "hotel" ? (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="58"
                                            height="58"
                                            viewBox="0 0 58 58"
                                          >
                                            <g
                                              id="Group_41574"
                                              data-name="Group 41574"
                                              transform="translate(-428.449 -411.648)"
                                            >
                                              <g
                                                id="Group_41516"
                                                data-name="Group 41516"
                                                transform="translate(-35.551 152.777)"
                                              >
                                                <circle
                                                  id="Ellipse_154"
                                                  data-name="Ellipse 154"
                                                  cx="29"
                                                  cy="29"
                                                  r="29"
                                                  transform="translate(464 258.871)"
                                                  fill="#02529c"
                                                />
                                              </g>
                                              <g
                                                id="hotel-svgrepo-com"
                                                transform="translate(442.449 424.715)"
                                              >
                                                <g
                                                  id="Group_41518"
                                                  data-name="Group 41518"
                                                >
                                                  <g
                                                    id="Group_41517"
                                                    data-name="Group 41517"
                                                  >
                                                    <path
                                                      id="Path_79185"
                                                      data-name="Path 79185"
                                                      d="M23.8,7.258V0H7.136V7.258H0V30.934H30.934V7.258ZM7.136,29.1h-5.3V9.1h5.3Zm7.412,0H12.986V24.86h1.562Zm3.4,0H16.386V24.86h1.562ZM21.96,7.258V29.1H19.785V23.022H11.148V29.1H8.974V1.838H21.96V7.258ZM29.1,29.1H23.8V9.1h5.3Z"
                                                      fill="#fff"
                                                    />
                                                  </g>
                                                </g>
                                                <g
                                                  id="Group_41520"
                                                  data-name="Group 41520"
                                                  transform="translate(12.799 3.767)"
                                                >
                                                  <g
                                                    id="Group_41519"
                                                    data-name="Group 41519"
                                                  >
                                                    <path
                                                      id="Path_79186"
                                                      data-name="Path 79186"
                                                      d="M215.337,62.345v1.909h-1.661V62.345h-1.838V68h1.838V66.092h1.661V68h1.838V62.345Z"
                                                      transform="translate(-211.838 -62.345)"
                                                      fill="#fff"
                                                    />
                                                  </g>
                                                </g>
                                                <g
                                                  id="Group_41522"
                                                  data-name="Group 41522"
                                                  transform="translate(11.148 11.344)"
                                                >
                                                  <g
                                                    id="Group_41521"
                                                    data-name="Group 41521"
                                                  >
                                                    <rect
                                                      id="Rectangle_23371"
                                                      data-name="Rectangle 23371"
                                                      width="1.838"
                                                      height="2.164"
                                                      fill="#fff"
                                                    />
                                                  </g>
                                                </g>
                                                <g
                                                  id="Group_41524"
                                                  data-name="Group 41524"
                                                  transform="translate(14.548 11.344)"
                                                >
                                                  <g
                                                    id="Group_41523"
                                                    data-name="Group 41523"
                                                  >
                                                    <rect
                                                      id="Rectangle_23372"
                                                      data-name="Rectangle 23372"
                                                      width="1.838"
                                                      height="2.164"
                                                      fill="#fff"
                                                    />
                                                  </g>
                                                </g>
                                                <g
                                                  id="Group_41526"
                                                  data-name="Group 41526"
                                                  transform="translate(17.948 11.344)"
                                                >
                                                  <g
                                                    id="Group_41525"
                                                    data-name="Group 41525"
                                                  >
                                                    <rect
                                                      id="Rectangle_23373"
                                                      data-name="Rectangle 23373"
                                                      width="1.838"
                                                      height="2.164"
                                                      fill="#fff"
                                                    />
                                                  </g>
                                                </g>
                                                <g
                                                  id="Group_41528"
                                                  data-name="Group 41528"
                                                  transform="translate(11.148 15.183)"
                                                >
                                                  <g
                                                    id="Group_41527"
                                                    data-name="Group 41527"
                                                  >
                                                    <rect
                                                      id="Rectangle_23374"
                                                      data-name="Rectangle 23374"
                                                      width="1.838"
                                                      height="2.164"
                                                      fill="#fff"
                                                    />
                                                  </g>
                                                </g>
                                                <g
                                                  id="Group_41530"
                                                  data-name="Group 41530"
                                                  transform="translate(14.548 15.183)"
                                                >
                                                  <g
                                                    id="Group_41529"
                                                    data-name="Group 41529"
                                                  >
                                                    <rect
                                                      id="Rectangle_23375"
                                                      data-name="Rectangle 23375"
                                                      width="1.838"
                                                      height="2.164"
                                                      fill="#fff"
                                                    />
                                                  </g>
                                                </g>
                                                <g
                                                  id="Group_41532"
                                                  data-name="Group 41532"
                                                  transform="translate(17.948 15.183)"
                                                >
                                                  <g
                                                    id="Group_41531"
                                                    data-name="Group 41531"
                                                  >
                                                    <rect
                                                      id="Rectangle_23376"
                                                      data-name="Rectangle 23376"
                                                      width="1.838"
                                                      height="2.164"
                                                      fill="#fff"
                                                    />
                                                  </g>
                                                </g>
                                                <g
                                                  id="Group_41534"
                                                  data-name="Group 41534"
                                                  transform="translate(11.148 19.022)"
                                                >
                                                  <g
                                                    id="Group_41533"
                                                    data-name="Group 41533"
                                                  >
                                                    <rect
                                                      id="Rectangle_23377"
                                                      data-name="Rectangle 23377"
                                                      width="1.838"
                                                      height="2.164"
                                                      fill="#fff"
                                                    />
                                                  </g>
                                                </g>
                                                <g
                                                  id="Group_41536"
                                                  data-name="Group 41536"
                                                  transform="translate(14.548 19.022)"
                                                >
                                                  <g
                                                    id="Group_41535"
                                                    data-name="Group 41535"
                                                  >
                                                    <rect
                                                      id="Rectangle_23378"
                                                      data-name="Rectangle 23378"
                                                      width="1.838"
                                                      height="2.164"
                                                      fill="#fff"
                                                    />
                                                  </g>
                                                </g>
                                                <g
                                                  id="Group_41538"
                                                  data-name="Group 41538"
                                                  transform="translate(17.948 19.022)"
                                                >
                                                  <g
                                                    id="Group_41537"
                                                    data-name="Group 41537"
                                                  >
                                                    <rect
                                                      id="Rectangle_23379"
                                                      data-name="Rectangle 23379"
                                                      width="1.838"
                                                      height="2.164"
                                                      fill="#fff"
                                                    />
                                                  </g>
                                                </g>
                                                <g
                                                  id="Group_41540"
                                                  data-name="Group 41540"
                                                  transform="translate(25.528 11.344)"
                                                >
                                                  <g
                                                    id="Group_41539"
                                                    data-name="Group 41539"
                                                  >
                                                    <rect
                                                      id="Rectangle_23380"
                                                      data-name="Rectangle 23380"
                                                      width="1.838"
                                                      height="2.164"
                                                      fill="#fff"
                                                    />
                                                  </g>
                                                </g>
                                                <g
                                                  id="Group_41542"
                                                  data-name="Group 41542"
                                                  transform="translate(25.528 15.183)"
                                                >
                                                  <g
                                                    id="Group_41541"
                                                    data-name="Group 41541"
                                                  >
                                                    <rect
                                                      id="Rectangle_23381"
                                                      data-name="Rectangle 23381"
                                                      width="1.838"
                                                      height="2.164"
                                                      fill="#fff"
                                                    />
                                                  </g>
                                                </g>
                                                <g
                                                  id="Group_41544"
                                                  data-name="Group 41544"
                                                  transform="translate(25.528 19.022)"
                                                >
                                                  <g
                                                    id="Group_41543"
                                                    data-name="Group 41543"
                                                  >
                                                    <rect
                                                      id="Rectangle_23382"
                                                      data-name="Rectangle 23382"
                                                      width="1.838"
                                                      height="2.164"
                                                      fill="#fff"
                                                    />
                                                  </g>
                                                </g>
                                                <g
                                                  id="Group_41546"
                                                  data-name="Group 41546"
                                                  transform="translate(25.528 22.935)"
                                                >
                                                  <g
                                                    id="Group_41545"
                                                    data-name="Group 41545"
                                                  >
                                                    <rect
                                                      id="Rectangle_23383"
                                                      data-name="Rectangle 23383"
                                                      width="1.838"
                                                      height="2.164"
                                                      fill="#fff"
                                                    />
                                                  </g>
                                                </g>
                                                <g
                                                  id="Group_41548"
                                                  data-name="Group 41548"
                                                  transform="translate(3.568 11.344)"
                                                >
                                                  <g
                                                    id="Group_41547"
                                                    data-name="Group 41547"
                                                  >
                                                    <rect
                                                      id="Rectangle_23384"
                                                      data-name="Rectangle 23384"
                                                      width="1.838"
                                                      height="2.164"
                                                      fill="#fff"
                                                    />
                                                  </g>
                                                </g>
                                                <g
                                                  id="Group_41550"
                                                  data-name="Group 41550"
                                                  transform="translate(3.568 15.183)"
                                                >
                                                  <g
                                                    id="Group_41549"
                                                    data-name="Group 41549"
                                                  >
                                                    <rect
                                                      id="Rectangle_23385"
                                                      data-name="Rectangle 23385"
                                                      width="1.838"
                                                      height="2.164"
                                                      fill="#fff"
                                                    />
                                                  </g>
                                                </g>
                                                <g
                                                  id="Group_41552"
                                                  data-name="Group 41552"
                                                  transform="translate(3.568 19.022)"
                                                >
                                                  <g
                                                    id="Group_41551"
                                                    data-name="Group 41551"
                                                  >
                                                    <rect
                                                      id="Rectangle_23386"
                                                      data-name="Rectangle 23386"
                                                      width="1.838"
                                                      height="2.164"
                                                      fill="#fff"
                                                    />
                                                  </g>
                                                </g>
                                                <g
                                                  id="Group_41554"
                                                  data-name="Group 41554"
                                                  transform="translate(3.568 22.935)"
                                                >
                                                  <g
                                                    id="Group_41553"
                                                    data-name="Group 41553"
                                                  >
                                                    <rect
                                                      id="Rectangle_23387"
                                                      data-name="Rectangle 23387"
                                                      width="1.838"
                                                      height="2.164"
                                                      fill="#fff"
                                                    />
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </svg>
                                        ) : (
                                          <>
                                            <img src="/assets/img/others.svg" alt="no more" />
                                          </>
                                        )}
                                        <div className="documents_accordian_description">
                                          <h6>{item?.title}</h6>
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: item?.description,
                                            }}
                                          ></p>
                                        </div>
                                      </div>
                                      <AttachmentContent
                                        label={""}
                                        files={[
                                          {
                                            name: item?.item,
                                            type: item?.item?.split(".").pop(),
                                            url: `${IMAGE_BASE_URL}${"booking"}/${item?.item
                                              }`,
                                          },
                                        ]}
                                      />
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <p style={{ textAlign: "center" }}>
                                No attachments available
                              </p>
                            )}
                          </Accordion.Body>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Documents;
