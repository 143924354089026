import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./itinerary.css";
import RentalDetails from "../../../components/admin/RentalDetails";
import { useDispatch, useSelector } from "react-redux";
import { getBookingItinerary } from "../../../redux/adminActionCreator";
import {
  customer_itinerary_details,
  customer_itinerary_loading,
} from "../../../redux/adminReducer";
import { Tab, Tabs } from "react-bootstrap";
import { IMAGE_BASE_URL } from "../../../constants/configuration";
import { Link } from "react-router-dom";
import { getDateRange } from "../../../utils/helpers";
import moment from "moment";

const Itinerary = () => {
  const dispatch = useDispatch();
  const bookingId = localStorage.getItem("booking_id");
  const bookingDetails = JSON.parse(localStorage.getItem("booking_details"));

  const details = useSelector(customer_itinerary_details);
  const loading = useSelector(customer_itinerary_loading);

  const [activeKey, setActiveKey] = useState(0);
  const [itinerary, setItinerary] = useState([]);
  const [dateArray, setDateArray] = useState([]);

  useEffect(() => {
    if (bookingId) {
      dispatch(getBookingItinerary(bookingId));
    }
  }, [bookingId]);

  useEffect(() => {
    if (details?.bookingitinerary?.length) {
      const mutableArray = details.bookingitinerary.map((item) => ({
        ...item,
      }));
      setItinerary(mutableArray);
    }
    if (details?.start_travel_date && details?.end_travel_date) {
      let dateArrayList = getDateRange(
        details?.start_travel_date,
        details?.end_travel_date
      );
      setDateArray(dateArrayList);
    }
  }, [details]);

  const dataChangeHandler = (value, key, index) => {
    let dataList = [...itinerary];
    dataList[index][key] = value;
    setItinerary(dataList);
  };

  

  return (
    <section id="itinerary">
      {details?.flight_booking && details?.flight_start_date ? (
        <div className="container px-4">
          <div className="row">
            <div className="col-12 py-3">
              <div className="card px-2">
                <div className="card-body">
                  <div className="flight_header">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.704"
                      height="20.503"
                      viewBox="0 0 20.704 20.503"
                    >
                      <path
                        d="M17.689,4.555a2.31,2.31,0,0,1,3.267,0h0a2.31,2.31,0,0,1,0,3.267l-2.722,2.722,2.029,8.1a1.089,1.089,0,0,1-.282,1.03l-.264.267a1.089,1.089,0,0,1-1.681-.161l-3.615-5.422-4.356,4.356v2.271a1.089,1.089,0,0,1-.319.77l-.306.306a1.089,1.089,0,0,1-1.716-.23L6.255,19.256,3.682,17.786a1.089,1.089,0,0,1-.23-1.716l.306-.306a1.089,1.089,0,0,1,.77-.319H6.8l4.356-4.356L5.734,7.475a1.089,1.089,0,0,1-.161-1.681l.267-.264a1.089,1.089,0,0,1,1.03-.282l8.1,2.029Z"
                        transform="translate(-2.13 -2.879)"
                        fill="none"
                        stroke="#02529c"
                        strokeWidth="2"
                      />
                    </svg>
                    <h4>FLIGHT DETAILS</h4>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="flight__det">
                        <h3>Start Flight</h3>
                        <div className="d-flex justify-content-between">
                          <p className="start_timing">
                            Departure :{" "}
                            {moment(details?.flight_start_date).format(
                              "DD MMM YYYY"
                            )}
                          </p>
                          <p className="start_timing">
                            Arrival :{" "}
                            {moment(details?.arrival_start_date).format(
                              "DD MMM YYYY"
                            )}
                          </p>
                        </div>
                        <div className="d-flex gap-2 justify-content-between">
                          <div className="flight_timing px-2">
                            <h4>{details?.flight_start_time}</h4>
                            <p>{details?.start_departure_airport}</p>
                          </div>
                          <div className="flight_hour">
                            <p>{details?.start_travel_time}</p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="266.185"
                              height="25.59"
                              viewBox="0 0 266.185 25.59"
                            >
                              <g
                                id="Group_41578"
                                data-name="Group 41578"
                                transform="translate(0 -32.136)"
                              >
                                <line
                                  id="Line_463"
                                  data-name="Line 463"
                                  x2="266.185"
                                  transform="translate(0 44.5)"
                                  fill="none"
                                  stroke="#072439"
                                  strokeWidth="2"
                                  strokeDasharray="8"
                                />
                                <g
                                  id="Group_40404"
                                  data-name="Group 40404"
                                  transform="translate(120.18 32.136)"
                                >
                                  <path
                                    id="Path_78466"
                                    data-name="Path 78466"
                                    d="M27,13.151c-.022.054-.049.108-.066.164A2.194,2.194,0,0,1,24.662,15c-2.081-.037-4.162-.078-6.242-.141a.57.57,0,0,0-.613.385c-1.664,3.337-3.345,6.667-5.01,10a.533.533,0,0,1-.566.346c-.985-.019-1.971-.007-3-.007l2.574-10.83c-.1-.012-.171-.027-.247-.029-2.116-.046-4.233-.086-6.349-.142a.573.573,0,0,0-.551.287c-.465.675-.957,1.332-1.426,2.005a.53.53,0,0,1-.5.263C1.844,17.122.953,17.13,0,17.13c.066-.152.111-.267.165-.378.586-1.22,1.169-2.442,1.766-3.658a.609.609,0,0,0,0-.6C1.339,11.291.761,10.079.179,8.87.124,8.755.077,8.636,0,8.459c.561,0,1.078,0,1.594,0A3.426,3.426,0,0,1,3,8.522c.379.193.594.714.875,1.1.254.345.514.686.745,1.046a.67.67,0,0,0,.664.347c2.08-.062,4.161-.1,6.242-.143.082,0,.164-.01.285-.019L9.236.029c.132-.008.243-.021.354-.021.892,0,1.784.01,2.676-.008a.481.481,0,0,1,.513.315c1.677,3.358,3.367,6.709,5.044,10.066a.524.524,0,0,0,.561.352c1.95-.057,3.9-.056,5.849-.146A2.409,2.409,0,0,1,27,12.438Z"
                                    transform="translate(0 0)"
                                    fill="#072439"
                                  />
                                </g>
                              </g>
                            </svg>
                            <p className="fly-shortdescription">
                              {details?.start_flight_stops}
                            </p>
                          </div>
                          <div className="flight_timing">
                            <h4>{details?.arrival_start_time}</h4>
                            <p>{details?.start_arrival_airport}</p>
                          </div>
                        </div>
                        <p>{details?.start_flight_description}</p>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="flight__det">
                        <h3>Return Flight</h3>
                        <div className="d-flex justify-content-between">
                          <p className="start_timing">
                            Departure :{" "}
                            {moment(details?.flight_return_date).format(
                              "DD MMM YYYY"
                            )}
                          </p>
                          <p className="start_timing">
                            Arrival :{" "}
                            {moment(details?.return_arrival_start_date).format(
                              "DD MMM YYYY"
                            )}
                          </p>
                        </div>

                        <div className="d-flex gap-2 justify-content-between">
                          <div className="flight_timing px-2">
                            <h4>{details?.flight_return_time}</h4>
                            <p>{details?.return_departure_airport}</p>
                          </div>
                          <div className="flight_hour">
                            <p>{details?.return_travel_time}</p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="266.185"
                              height="25.59"
                              viewBox="0 0 266.185 25.59"
                            >
                              <g
                                id="Group_41578"
                                data-name="Group 41578"
                                transform="translate(0 -32.136)"
                              >
                                <line
                                  id="Line_463"
                                  data-name="Line 463"
                                  x2="266.185"
                                  transform="translate(0 44.5)"
                                  fill="none"
                                  stroke="#072439"
                                  strokeWidth="2"
                                  strokeDasharray="8"
                                />
                                <g
                                  id="Group_40404"
                                  data-name="Group 40404"
                                  transform="translate(120.18 32.136)"
                                >
                                  <path
                                    id="Path_78466"
                                    data-name="Path 78466"
                                    d="M27,13.151c-.022.054-.049.108-.066.164A2.194,2.194,0,0,1,24.662,15c-2.081-.037-4.162-.078-6.242-.141a.57.57,0,0,0-.613.385c-1.664,3.337-3.345,6.667-5.01,10a.533.533,0,0,1-.566.346c-.985-.019-1.971-.007-3-.007l2.574-10.83c-.1-.012-.171-.027-.247-.029-2.116-.046-4.233-.086-6.349-.142a.573.573,0,0,0-.551.287c-.465.675-.957,1.332-1.426,2.005a.53.53,0,0,1-.5.263C1.844,17.122.953,17.13,0,17.13c.066-.152.111-.267.165-.378.586-1.22,1.169-2.442,1.766-3.658a.609.609,0,0,0,0-.6C1.339,11.291.761,10.079.179,8.87.124,8.755.077,8.636,0,8.459c.561,0,1.078,0,1.594,0A3.426,3.426,0,0,1,3,8.522c.379.193.594.714.875,1.1.254.345.514.686.745,1.046a.67.67,0,0,0,.664.347c2.08-.062,4.161-.1,6.242-.143.082,0,.164-.01.285-.019L9.236.029c.132-.008.243-.021.354-.021.892,0,1.784.01,2.676-.008a.481.481,0,0,1,.513.315c1.677,3.358,3.367,6.709,5.044,10.066a.524.524,0,0,0,.561.352c1.95-.057,3.9-.056,5.849-.146A2.409,2.409,0,0,1,27,12.438Z"
                                    transform="translate(0 0)"
                                    fill="#072439"
                                  />
                                </g>
                              </g>
                            </svg>
                            <p className="fly-shortdescription">
                              {details?.return_flight_stops}
                            </p>
                          </div>
                          <div className="flight_timing">
                            <h4>{details?.return_arrival_start_time}</h4>
                            <p>{details?.return_arrival_airport}</p>
                          </div>
                        </div>
                        <p>{details?.return_flight_description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {details?.pick_up_date ? (
        <div className="container px-4">
          <div className="row">
            <div className="col-12 py-3">
              <div className="card px-2">
                <div className="card-body">
                  <div className="car_rental_header">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.817"
                      height="17"
                      viewBox="0 0 20.817 17"
                    >
                      <path
                        id="car-svgrepo-com"
                        d="M3,8l2.722,2.268a1,1,0,0,0,.64.232H17.638a1,1,0,0,0,.64-.232L21,8M6.5,14h.01M17.5,14h.01M8.161,4.5h7.679a2,2,0,0,1,1.736,1.008l2.9,5.07A4,4,0,0,1,21,12.562V18.5a1,1,0,0,1-1,1H19a1,1,0,0,1-1-1v-1H6v1a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V12.562a4,4,0,0,1,.527-1.985l2.9-5.07A2,2,0,0,1,8.161,4.5ZM7,14a.5.5,0,1,1-.5-.5A.5.5,0,0,1,7,14Zm11,0a.5.5,0,1,1-.5-.5A.5.5,0,0,1,18,14Z"
                        transform="translate(-1.592 -3.5)"
                        fill="none"
                        stroke="#02529c"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </svg>

                    <h4>CAR RENTAL</h4>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 pe-0 table_layout">
                      <RentalDetails
                        heading="PICKUP"
                        date={moment(details?.pick_up_date).format(
                          "DD MMM YYYY"
                        )}
                        time={details?.pick_up_time}
                        location={details?.pick_up_location}
                      />
                    </div>

                    <div className="col-12 col-md-6 ps-0">
                      <RentalDetails
                        heading="DROPOFF"
                        date={moment(details?.drop_off_date).format(
                          "DD MMM YYYY"
                        )}
                        time={details?.drop_off_time}
                        location={details?.drop_off_location}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="container px-4">
        <div className="itinerary_header">
          <h4>Itinerary</h4>
        </div>
      </div>
      <div className="container px-4">
        <div className="row">
          <div className="col-12 py-3">
            <div className="card px-2">
              <div className="card-body">
                <Accordion defaultActiveKey="0" flush activeKey={activeKey}>
                  {itinerary?.map((ele, i) => {
                    const isFirst = i === 0; 
                    const isLast = i === itinerary.length - 1; 
                    const hideLast =
                      isLast && details?.is_oneday_travel === false; 

                    return (
                      <Accordion.Item eventKey={i} key={i}>
                        <Accordion.Header
                          onClick={() => setActiveKey(activeKey === i ? null : i)}
                        >
                          <div className="itinerary_header_content">
                            <h6 className="accordion_header">
                              Day {i + 1}: {ele?.title}
                            </h6>
                            <div className="me-4 itinerary_date">
                              <p className="mb-0">{dateArray[i]}</p>
                            </div>
                          </div>
                        </Accordion.Header>

                        <Accordion.Body>
                          <div className="row py-3">
                            {!isFirst && !hideLast && (
                              <div className="col-12 col-md-4">
                                <img
                                  src={`${IMAGE_BASE_URL}package/${ele?.map_image}`}
                                  alt={ele?.map_image_alt}
                                  className="itinarary_image_views"
                                />
                              </div>
                            )}

                            <div
                              className={`col-12 ${!isFirst && !hideLast ? "col-md-8 ps-4" : ""
                                }`}
                            >
                              <div className="itinarary_description">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: ele?.description,
                                  }}
                                ></p>
                              </div>
                            </div>
                          </div>

                          {!isFirst && !hideLast && !ele?.read_more && (
                            <Tabs id="controlled-tab-example" className="mb-3">
                              <Tab eventKey="home" title="Attractions">
                                <div className="itinerary__activitiesAttractions">
                                  <div className="row mt-3">
                                    {ele?.attraction?.map((attraction, idx) => (
                                      <div className="col-md-3" key={idx}>
                                        <Link
                                          to={`/attraction-details/${attraction?.handle_key}/${bookingDetails?.bookingitinerary?.handle_key}`}
                                          target="_blank"
                                        >
                                          <div className="atraction">
                                            <div className="image">
                                              <img
                                                src={`${IMAGE_BASE_URL}${attraction?.path}/${attraction?.image}`}
                                                alt={attraction.image_alt}
                                                className="w-100"
                                              />
                                            </div>
                                            <div className="contents">
                                              <h3>{attraction?.title}</h3>
                                              <p
                                                dangerouslySetInnerHTML={{
                                                  __html: attraction?.short_description,
                                                }}
                                              ></p>
                                            </div>
                                          </div>
                                        </Link>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </Tab>
                              <Tab eventKey="profile" title="Activities">
                                <div className="itinerary__activitiesAttractions">
                                  <div className="row mt-3">
                                    {ele?.activity?.map((activity, idx) => (
                                      <div className="col-md-3" key={idx}>
                                        <Link
                                          to={`/activity-details/${activity?.handle_key}/${bookingDetails?.bookingitinerary?.handle_key}`}
                                          target="_blank"
                                        >
                                          <div className="atraction">
                                            <div className="image">
                                              <img
                                                src={`${IMAGE_BASE_URL}${activity?.path}/${activity?.image}`}
                                                alt={activity.image_alt}
                                                className="w-100"
                                              />
                                            </div>
                                            <div className="contents">
                                              <h3>{activity?.title}</h3>
                                              <p>
                                                <img
                                                  src="/assets/img/icons/clock.svg"
                                                  height={17}
                                                  alt=""
                                                />
                                                Duration {activity?.duration} hours
                                              </p>
                                            </div>
                                          </div>
                                        </Link>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </Tab>
                              <Tab eventKey="contact" title="View Map">
                                <img
                                  src={`${IMAGE_BASE_URL}package/${ele?.map_image}`}
                                  alt={ele?.map_image_alt}
                                  className="w-100"
                                />
                              </Tab>
                            </Tabs>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}


                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Itinerary;
